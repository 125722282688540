import { Box, Button, Container, Typography } from "@mui/material";
import React, { useState } from "react";
import Layout from "../Layout/Layout";
import vBack from "../../assets/images/vBack.jpg";
import { forgetPasswordUser } from "../../services/Auth";

function ForgetPassword({ active, setActive }) {
  const [email, setEmail] = useState("");
  const sentMail = () => {
    forgetPasswordUser(email).then((data) => {
      console.log("reset email sent");
    });
  };
  const emailChange = (e) => {
    setEmail(e.target.value);
  };
  return (
    <>
      <Box sx={style.mainBox}>
        <Layout active={active} setActive={setActive}>
          <Container sx={style.container}>
            <Typography
              variant="h3"
              sx={{ color: "white", fontWeight: 800, marginY: "3rem" }}
            >
              Forget Password
            </Typography>
            <input
              className="inputs"
              style={{
                width: "60%",
                marginBottom: "1rem",
                color: "black",
                border: "1px solid white",
                borderRadius: "10rem",
                paddingTop: "1.5rem",
                paddingLeft: "1.5rem",
                fontSize: "1.2rem",
                paddingBottom: "2rem",
                "::placeholder": {
                  color: "black",
                },
                "::focus-visible": {
                  border: "none",
                  outline: "none",
                },
              }}
              placeholder="Enter Your Mail"
              onChange={emailChange}
            />
            <Button
              onClick={sentMail}
              variant="contained"
              sx={[style.button, { backgroundColor: "#FCA10C" }]}
            >
              SEND
            </Button>
          </Container>
        </Layout>
      </Box>
    </>
  );
}

export default ForgetPassword;
const style = {
  main: {
    backgroundColor: "grey",
    width: "100%",
  },
  mainBox: {
    background: `url(${vBack}) no-repeat`,
    backgroundSize: "cover",
    position: "absolute",
    top: "0px",
    width: "100%",
    height: "100vh",
  },
  container: {
    maxWidth: { lg: "1400px", md: "1350px" },
    paddingBottom: "3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginTop: "5rem",
  },
  button: {
    backgroundColor: "#ec5d3b",
    padding: "20px 3px",
    marginY: "1rem",
    border: "none",
    borderRadius: "50px",
    color: "#fff",
    textTransform: "uppercase",
    fontWeight: "600",
    width: "40%",
    ":hover": {
      backgroundColor: "#ec5d3b",
    },
  },
};
