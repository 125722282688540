import React from "react";
import { Box } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment } from "@mui/material";
import header from "../header/header.css";

const SearchBar = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderRadius: "1.8rem",
        border: "1px solid white",
        paddingX: "1rem",
        marginTop: "0.5rem",
      }}
    >
      <input
        className={header.etc}
        placeholder="Search"
        style={{
          color: "white",
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "50px",
          border: "0px",
          outline: "none",
          width: "100%",
          "::focus-visible": {
            outline: "none",
            borderColor: "white",
          },
          ":placeholder": {
            color: "white",
            opacity: 1,
          },
        }}
      />
      <InputAdornment
        style={{
          borderRadius: "50%",
          color: "#fff !important",
          backgroundColor: "white",
          height: "30px",
          width: "30px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <SearchIcon />
      </InputAdornment>
    </Box>
  );
};

export default SearchBar;
