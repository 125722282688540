import React from "react";
import Header from "../header/Header";
import Footer from "../footer/footer";
import Mainslider from "../mainslider/Mainslider";

import { Box } from "@mui/material";

function Home({ active, setActive }) {
  return (
    <>
      <Header active={active} setActive={setActive} />
      <Box
        sx={{
          position: "absolute",
          zIndex: "-1",
          top: "0px",
        }}
      >
        <Mainslider />
      </Box>
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          zIndex: 9,
          width: "100%",
        }}
      >
        <Footer />
      </div>
    </>
  );
}

export default Home;
