import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Header from "../header/Header";
import Footer from "../footer/footer";
import vBack from "../../assets/images/vBack.jpg";
import s1 from "../../assets/images/s1.jpg";
import s2 from "../../assets/images/s2.jpg";
import s3 from "../../assets/images/s3.jpg";
import s4 from "../../assets/images/s4.jpg";
import s5 from "../../assets/images/s5.jpg";
import s6 from "../../assets/images/s6.jpg";
import { Link } from "react-router-dom";
import Layout from "../Layout/Layout";
import { motion } from "framer-motion";

function store({ active, setActive }) {
  const style = {
    main: {
      position: "absolute",

      width: "100%",

      backgroundImage: `url(${vBack})`,
      backgroundSize: "cover",
    },
    Container: {
      maxWidth: { lg: "1500px" },
      paddingBottom: "1rem",
    },
    imgContainer: {
      width: "100%",
      height: "100%",
      borderRadius: "1rem",
      position: "relative",
      marginTop: "1.5rem",
      transition: "all ease-in-out 0.5s",
      transform: "scale(1)",

      ":hover": {
        transform: "scale(1.05)",
        position: "relative",
        zIndex: 1,
      },
    },
    imgContainer1: {
      width: "100%",
      height: "50%",
      borderRadius: "1rem",
      position: "relative",
      marginY: "1rem",
      transform: "scale(0.9)",
      transition: "all ease-in-out 0.5s",
      ":hover": {
        transform: "scale(1)",

        position: "relative",
        zIndex: 1,
      },
    },
    text: {
      color: "white",
      fontSize: "2.5rem",
      fontWeight: 700,
    },
    textBox: {
      position: "absolute",
      zIndex: 99,
      bottom: 20,
      left: 20,
    },
  };
  return (
    <>
      <Box sx={style.main}>
        <Layout active={active} setActive={setActive}>
          <Container sx={style.Container}>
            <Grid container columnSpacing={3} justifyContent="center">
              <Grid item lg={5} xs={12}>
                <motion.div
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.8, type: "spring", stiffness: 50 }}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Link to="https://merchandise.bizproquo.net/">
                    <Box sx={style.imgContainer}>
                      <img
                        src={s1}
                        loading="eager"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "2rem",
                        }}
                      />
                      <Box sx={style.textBox}>
                        <Typography sx={style.text}>SHOES</Typography>
                      </Box>
                    </Box>
                  </Link>
                </motion.div>
              </Grid>
              <Grid item lg={3} md={6} xs={12}>
                <motion.div
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.8, type: "spring", stiffness: 50 }}
                  style={{
                    width: "100%",
                  }}
                >
                  <Link to="https://merchandise.bizproquo.net/">
                    <Box sx={style.imgContainer1}>
                      <img
                        src={s2}
                        loading="eager"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "2rem",
                        }}
                      />
                      <Box sx={style.textBox}>
                        <Typography sx={style.text}>T-SHIRT</Typography>
                      </Box>
                    </Box>
                  </Link>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.8, type: "spring", stiffness: 50 }}
                  style={{
                    width: "100%",
                  }}
                >
                  <Link to="https://merchandise.bizproquo.net/">
                    <Box sx={style.imgContainer1}>
                      <img
                        src={s3}
                        loading="eager"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "2rem",
                        }}
                      />
                      <Box sx={style.textBox}>
                        <Typography sx={style.text}>CLOTHES</Typography>
                      </Box>
                    </Box>
                  </Link>
                </motion.div>
              </Grid>

              <Grid item lg={3} md={6} xs={12}>
                <motion.div
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.8, type: "spring", stiffness: 50 }}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                >
                  <Link to="https://merchandise.bizproquo.net/">
                    <Box sx={style.imgContainer1}>
                      <img
                        src={s5}
                        loading="eager"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "2rem",
                        }}
                      />
                      <Box sx={style.textBox}>
                        <Typography sx={style.text}>BAGS</Typography>
                      </Box>
                    </Box>
                  </Link>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.8, type: "spring", stiffness: 50 }}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Link to="https://merchandise.bizproquo.net/">
                    <Box sx={style.imgContainer1}>
                      <img
                        src={s6}
                        loading="eager"
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "2rem",
                        }}
                      />
                      <Box sx={style.textBox}>
                        <Typography sx={style.text}>WATCHES</Typography>
                      </Box>
                    </Box>
                  </Link>
                </motion.div>
              </Grid>
            </Grid>
          </Container>
        </Layout>
      </Box>
    </>
  );
}

export default store;
