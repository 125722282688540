import { collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { DataArrayOutlined } from "@mui/icons-material";

export const getSubs = async (uid, data) => {
  console.log(uid, "uid");
  const collec = doc(db, "userSignUp", uid);
  try {
    const docSnap = await getDoc(collec);
    console.log(docSnap.data(), "docsnap");
    const update = await updateDoc(collec, {
      subscription: data,
    });
    return {
      status: 200,
    };
  } catch (error) {
    throw new Error(error);
  }
};

export const getSubsData = async (uid) => {
  const collec = doc(db, "userSignUp", uid);
  try {
    const docSnap = await getDoc(collec);
    const data = docSnap.data();
    return data;
  } catch (error) {
    throw new Error(error);
  }
};
