import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../firebaseConfig";

export const getBlog = async () => {
  let Data = [];
  const querySnapshot = await getDocs(collection(db, "Blog"));
  querySnapshot.forEach((doc) => {
    Data.push(doc.data());
  });
  console.log(Data, "Blog Data");
  return Data;
};
export const AddCountInBlog = async (data) => {
  const Ref = collection(db, "Blog");
  const q = query(Ref, where("Name", "==", data.Name));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(async (docs) => {
    const docRef = doc(db, "Blog", docs.id);

    await updateDoc(docRef, data);
  });
};
