import { Box } from "@mui/material";
import React from "react";
import Header from "../header/Header";
import Footer from "../Product/Footer";

function Layout({ active, setActive, children }) {
  return (
    <>
      <Header active={active} setActive={setActive} />
      {children}
      <Footer />
    </>
  );
}

export default Layout;
