import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import Layout from "../Layout/Layout";
import vBack from "../../assets/images/vBack.jpg";
import AlarmIcon from "@mui/icons-material/Alarm";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { collection, getDocs } from "@firebase/firestore";
import { db } from "../../firebaseConfig";
import { AddCountInBlog } from "../../services/Blog";

function Details({ active, setActive }) {
  const [data, setData] = useState();
  const [blogData, setBlogData] = useState([]);
  useEffect(() => {
    const getDetails = JSON.parse(localStorage.getItem("detail blog"));
    setData(getDetails);
  }, []);
  useEffect(() => {
    const blog = async () => {
      const ref = collection(db, "Blog");
      const querySnapshot = await getDocs(ref);
      const blogData = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        const dateInSeconds = data.date; // Assuming data.date is in seconds
        const timezoneOffsetInMilliseconds =
          new Date().getTimezoneOffset() * 60 * 1000;
        const dateInMillis =
          dateInSeconds * 1000 - timezoneOffsetInMilliseconds; // Adjust for timezone offset
        const date = new Date(dateInMillis);

        const monthName = new Intl.DateTimeFormat("en-US", {
          month: "long",
        }).format(date);
        const dateNumber = date.getDate();

        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(data.description, "text/html");
        const textDescription = htmlDoc.body.textContent || "";

        const truncatedDescription =
          textDescription.length > 50
            ? textDescription.substring(0, 40) + "..."
            : textDescription;

        return {
          ...data,
          truncatedDescription,
          monthName,
          dateNumber,
        };
      });

      setBlogData(blogData);
      console.log(blogData);
    };

    blog();
  }, []);
  return (
    <Box sx={style.main}>
      <Layout active={active} setActive={setActive}>
        <Container sx={style.Container}>
          <Typography sx={style.Headings}>Detailed Blog</Typography>
          <Grid container columnSpacing={7} rowSpacing={7}>
            <Grid item lg={8} xs={12}>
              <Box sx={style.blogcard}>
                <Grid container sx={{ width: "100%", height: "100%" }}>
                  <Grid item lg={6} md={4} sm={6} xs={12}>
                    <Box sx={style.imgSide}>
                      <img
                        src={data?.image}
                        alt="blogs image"
                        style={{ width: "100%", height: "100%" }}
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={6} md={8} sm={6} xs={12}>
                    <Box sx={style.gradientBox}>
                      <Box sx={style.gradientInnerBox}>
                        <Box sx={style.cardTop}>
                          <Box sx={style.gradientBox1}>
                            <Typography sx={style.numberfont}>
                              {data?.dateNumber}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",

                              flexDirection: "column",
                            }}
                          >
                            <Typography sx={style.numberfont1}>
                              {data?.monthName}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",

                                flexDirection: "row",
                                gap: "10px",
                              }}
                            >
                              <AccessTimeIcon sx={{ color: "white" }} />
                              <Typography sx={style.Time}>
                                {data?.time}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            marginY: "0.5rem",
                          }}
                        >
                          <Typography
                            variant="h6"
                            sx={{ fontWeight: 800, color: "white" }}
                          >
                            {data?.Name}
                          </Typography>
                          <Typography
                            sx={{
                              width: "100%",
                              marginY: "0.5rem",
                              color: "white !important",
                              maxWidth: "470px",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: data?.description,
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item lg={4} xs={12} sx={{ marginTop: "-4rem" }}>
              <Typography sx={style.Heading}>RELATED BLOG</Typography>
              <Grid container rowSpacing={5}>
                {blogData?.map((img, index) => {
                  return (
                    <Grid item xs={12} key={index}>
                      <Box sx={style.blogcard}>
                        <Grid container sx={{ height: "100%" }}>
                          <Grid item lg={6} md={4} sm={5} xs={6}>
                            <Box
                              sx={{
                                width: "100%",
                                height: { sm: "250px", xs: "100%" },
                              }}
                            >
                              <img
                                src={img?.image}
                                alt="blogs image"
                                style={{ width: "100%", height: "100%" }}
                              />
                            </Box>
                          </Grid>
                          <Grid item lg={6} md={4} sm={5} xs={6}>
                            <Box sx={style.gradient}>
                              <Box sx={style.gradientInnerBox}>
                                <Box sx={style.cardTop}>
                                  <Box sx={style.gradientBox0}>
                                    <Typography sx={style.numberfont1}>
                                      {img.dateNumber}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",

                                      flexDirection: "column",
                                    }}
                                  >
                                    <Typography sx={style.numberfont0}>
                                      {img.monthName}
                                    </Typography>
                                    <Box
                                      sx={{
                                        display: "flex",

                                        flexDirection: "row",
                                        gap: "10px",
                                        alignItems: "center",
                                      }}
                                    >
                                      <AccessTimeIcon sx={{ color: "white" }} />
                                      <Typography sx={style.Time}>
                                        {img.time}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    variant="h5"
                                    sx={{
                                      fontWeight: 800,
                                      color: "white",
                                      fontSize: {
                                        lg: "18px",
                                        md: "22px",
                                        xs: "1rem",
                                      },
                                    }}
                                  >
                                    {img.Name}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Layout>
    </Box>
  );
}

export default Details;
const style = {
  main: {
    width: "100%",
    position: "absolute",
    background: `url(${vBack}) no-repeat`,
    backgroundSize: "cover",
  },
  Container: {
    maxWidth: { lg: "1600px", md: "1200px" },
  },
  Headings: {
    fontSize: { md: "4rem", sm: "2rem", xs: "1.5rem" },
    fontWeight: 800,
    color: "white",
    letterSpacing: "2px",
    fontStyle: "italic",
    paddingY: "1rem",
  },
  Heading: {
    fontSize: { md: "2.5rem", sm: "2rem", xs: "1.5rem" },
    fontWeight: 700,
    color: "white",
    letterSpacing: "2px",
    fontStyle: "italic",
    paddingY: "1rem",
  },
  blogcard: {
    width: "100%",
    height: "100%",
    transition: "all ease-in-out 0.5s",
  },
  imgSide: {
    width: "100%",
    height: { lg: "700px", md: "500px", sm: "350px" },
  },
  gradientBox0: {
    paddingX: "20px",
    height: "70px",
    background: " #FDC830" /* fallback for old browsers */,
    background:
      "-webkit-linear-gradient(135deg, #F37335, #FDC830)" /* Chrome 10-25, Safari 5.1-6 */,
    background: "linear-gradient(135deg, #F37335, #FDC830)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  gradientBox: {
    height: { lg: "700px", md: "500px", sm: "350px", xs: "500px" },
    width: "100%",
    overflowY: "scroll",
    overflowX: "hidden",

    background: " #FDC830" /* fallback for old browsers */,
    background:
      "-webkit-linear-gradient(135deg, #F37335, #FDC830)" /* Chrome 10-25, Safari 5.1-6 */,
    background: "linear-gradient(135deg, #F37335, #FDC830)",

    "&::-webkit-scrollbar-track": {
      BoxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
      backgroundColor: "transparent",
      borderRadius: "10px",
    },

    " &::-webkit-scrollbar": {
      width: "10px",
      backgroundColor: "transparent",
    },

    " &::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      background: "#F58135",
    },
  },
  gradientBox1: {
    paddingX: "20px",
    height: "100%",
    background: " #FDC830" /* fallback for old browsers */,
    background:
      "-webkit-linear-gradient(135deg, #F37335, #FDC830)" /* Chrome 10-25, Safari 5.1-6 */,
    background:
      "linear-gradient(135deg, #F37335, #FDC830)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */,
  },
  gradientBox2: {
    paddingX: "10px",
    height: "100%",
  },
  gradient: {
    paddingX: "10px",
    height: "100%",
    // width: "100%",
  },
  gradientInnerBox: {
    width: "100%",
    height: "100%",
    padding: "1rem",
  },
  numberfont: {
    color: "white",
    fontWeight: 800,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "2rem",
    padding: "1.5rem",
  },
  numberfont2: {
    color: "white",
    fontWeight: 800,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "18px",
    padding: "1rem",
  },
  numberfont1: {
    color: "white",
    fontWeight: 800,
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    fontSize: "2.5rem",
  },
  numberfont0: {
    color: "white",
    fontWeight: 800,
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    fontSize: { xl: "2rem", xs: "16px" },
  },
  cardTop: {
    display: { sm: "flex", xs: "block" },
    flexDirection: "row",
    gap: "1rem",
    alignItems: "center",
  },
  Time: {
    color: "white",
    fontSize: "11px",
  },
};
