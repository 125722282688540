import React from "react";
import {
  Box,
  Grid,
  Typography,
  Container,
  List,
  ListItem,
} from "@mui/material";
import Newsletter from "../newletter/Newsletter.jsx";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import MusicNoteIcon from "@mui/icons-material/MusicNote";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";

const Header = () => {
  return (
    <Box sx={styles.footerSec}>
      <Container maxWidth="xl" rowSpacing={0} pb={0}>
        <Grid container justifyContent="center">
          <Grid item lg={4} md={6} xs={12}>
            <Typography sx={styles.text}>STAY CONNECTED</Typography>
          </Grid>
          <Grid item xs={12} sm={9} md={6} lg={8}>
            <Newsletter />
          </Grid>
        </Grid>
        <Grid container maxWidth="xl" rowSpacing={1} pb={0}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              sx={{
                alignSelf: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: { sm: "row", xs: "column" },
              }}
            >
              <List>
                <ListItem
                  sx={{
                    display: { md: "flex", xs: "none !important" },
                  }}
                >
                  <InstagramIcon sx={styles.icon} />
                  <FacebookIcon sx={styles.icon} />
                  <YouTubeIcon sx={styles.icon} />
                  <MusicNoteIcon sx={styles.icon} />
                  <GraphicEqIcon sx={styles.icon} />
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Header;

const styles = {
  footerSec: {
    width: "100%",

    h2: {
      textAlign: "right",
      color: "#FFF",
      textTransform: "uppercase",

      fontSize: "40px",
      fontWeight: "600",
    },
  },
  socialtags: {},
  icon: {
    width: "40px",
    height: "40px",
    color: "white",
    backgroundColor: "rgb(255, 255, 255,0.2)",
    borderRadius: "50%",
    padding: "0.5rem",
    marginX: "1rem",
    ":hover": {
      backgroundColor: "#fda10b",
      transition: "all ease 0.5s",
    },
  },
  text: {
    fontSize: { xl: "3rem", md: "1.5rem" },
    color: "white",
    fontWeight: 700,
    textAlign: { md: "left", xs: "center" },
  },
};
