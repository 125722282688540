import { Box, Container, Grid, Typography, Button } from "@mui/material";
import React from "react";
import Layout from "../Layout/Layout";
import vBack from "../../assets/images/vBack.jpg";
import PhoneIcon from "@mui/icons-material/Phone";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import MarkunreadIcon from "@mui/icons-material/Markunread";

function Contact({ active, setActive }) {
  const style = {
    mainBox: {
      background: `url(${vBack}) no-repeat`,
      backgroundSize: "cover",
      position: "absolute",
      top: "0px",
      width: "100%",
    },
    container: {
      maxWidth: { lg: "1400px", md: "1350px" },
      paddingBottom: "3rem",
    },
    contact: { marginY: "3rem", width: "100%" },
    QuickContact: {
      textAlign: "center",
      color: "white",
      fontWeight: 800,
      marginBottom: "2rem",
    },
    input: {
      marginY: "0.5rem",
      color: "white",
      border: "1px solid white",
      borderRadius: "1rem",
      width: "100%",
      height: "100%",
      background: "transparent",
    },

    button: {
      backgroundColor: "#ec5d3b",
      padding: "24px 3px",

      border: "none",
      borderRadius: "50px",
      color: "#fff",
      textTransform: "uppercase",
      fontWeight: "600",
      width: { md: "95%", xs: "100%" },
      ":hover": {
        backgroundColor: "#ec5d3b",
      },
    },
    contactBox: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "Center",
      justifyContent: "center",
    },
    icon: {
      width: "100px",
      height: "100px",
      display: "flex",
      alignItems: "Center",
      justifyContent: "center",

      borderRadius: "50%",
      border: "3px solid white",
    },
  };
  return (
    <>
      <Box sx={style.mainBox}>
        <Layout active={active} setActive={setActive}>
          <Container sx={style.container}>
            <Box sx={style.contact}>
              <Typography variant="h3" sx={style.QuickContact}>
                QUICK CONTACT
              </Typography>
              <Grid container columnSpacing={3} justifyContent={"center"}>
                <Grid item lg={6} xs={12} justifyContent={"center"}>
                  <input
                    style={{
                      marginBottom: "1rem",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: "1rem",
                      paddingTop: "1.5rem",
                      background: "transparent",
                      paddingLeft: "1.5rem",
                      fontSize: "1.2rem",
                      paddingBottom: "2rem",
                    }}
                    placeholder="Name"
                    rows="4"
                  />
                  <input
                    style={{
                      marginBottom: "1rem",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: "1rem",
                      paddingTop: "1.5rem",
                      background: "transparent",
                      paddingLeft: "1.5rem",
                      fontSize: "1.2rem",
                      paddingBottom: "2rem",
                    }}
                    placeholder="Email"
                    rows="4"
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <input
                    style={{
                      width: "100%",
                      marginBottom: "1rem",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: "1rem",
                      paddingTop: "1.5rem",
                      background: "transparent",
                      paddingLeft: "1.5rem",
                      fontSize: "1.2rem",
                      paddingBottom: "8rem",
                    }}
                    placeholder="How can we help you ?"
                    rows="4"
                  />
                </Grid>
                <Grid
                  item
                  lg={6}
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <input
                    style={{
                      width: "100%",
                      marginBottom: "1rem",
                      color: "white",
                      border: "1px solid white",
                      borderRadius: "1rem",
                      paddingTop: "1.5rem",
                      background: "transparent",
                      paddingLeft: "1.5rem",
                      fontSize: "1.2rem",
                      paddingBottom: "2rem",
                    }}
                    placeholder="Phone"
                    rows="4"
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <Button variant="contained" sx={style.button}>
                    Search
                  </Button>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" marginY="2rem">
                <Grid item sm={3} xs={12}>
                  <Box sx={style.contactBox}>
                    <Box sx={style.icon} backgroundColor="black">
                      <PhoneIcon sx={{ color: "white" }} fontSize="large" />
                    </Box>
                    <Typography
                      sx={{
                        color: "white",
                        textAlign: "center",
                        marginY: "1rem",
                        fontWeight: 600,
                      }}
                    >
                      PHONE
                    </Typography>
                    <Typography
                      sx={{
                        color: "white",
                        textAlign: "center",
                        marginY: "1rem",
                        fontWeight: 600,
                      }}
                    >
                      + 123-45609
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={3} xs={12}>
                  <Box sx={style.contactBox}>
                    <Box sx={style.icon} backgroundColor="#fda10b">
                      <FmdGoodIcon sx={{ color: "white" }} fontSize="large" />
                    </Box>
                    <Typography
                      sx={{
                        color: "white",
                        textAlign: "center",
                        marginY: "1rem",
                        fontWeight: 600,
                      }}
                    >
                      ADDRESS
                    </Typography>
                    <Typography
                      sx={{
                        color: "white",
                        textAlign: "center",
                        marginY: "1rem",
                        fontWeight: 600,
                      }}
                    >
                      street 123 Nesty Town(123)
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={3} xs={12}>
                  <Box sx={style.contactBox}>
                    <Box sx={style.icon} backgroundColor="#ec5d3b">
                      <MarkunreadIcon
                        sx={{ color: "white" }}
                        fontSize="large"
                      />
                    </Box>
                    <Typography
                      sx={{
                        color: "white",
                        textAlign: "center",
                        marginY: "1rem",
                        fontWeight: 600,
                      }}
                    >
                      EMAIL
                    </Typography>
                    <Typography
                      sx={{
                        color: "white",
                        textAlign: "center",
                        marginY: "1rem",
                        fontWeight: 600,
                      }}
                    >
                      info@example.com
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Layout>
      </Box>
    </>
  );
}
export default Contact;
