import React from "react";
import { Box, Container, Typography, Grid } from "@mui/material";
import p9 from "../../assets/images/p9.jpg";

function RelatedProduct() {
  const style = {
    buzzyText: {
      textAlign: "center",
      fontFamily: "Bassy",
      marginTop: "2rem",
      color: "white",
    },
    head: {
      textAlign: "center",
      marginY: "2rem",
      color: "black",

      fontWeight: "700",
      fontStyle: "italic",
    },
    cont: {
      paddingY: "3rem",
    },
    prices: {
      fontWeight: 700,
      fontSize: "1rem",
      textAlign: "center",
      marginY: "1.3rem",
      color: "white",
    },
    productBox: {
      width: 400,
      height: 500,
      marginX: 2,
    },
  };
  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: "2rem",
          marginY: "0.7rem",
          color: "white",
        }}
      >
        Related Product
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        {[0, 0, 0, 9].map(() => (
          <Box sx={style.productBox}>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                ":hover": {
                  transform: "scale(1.1)",
                  transition: "all ease 0.5s",
                },
              }}
            >
              <img
                src={p9}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "1rem",
                }}
              />
            </Box>
            <Typography sx={style.prices}>H6 Loard of the string </Typography>
            <Typography sx={style.prices}> $ 45.00</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default RelatedProduct;
