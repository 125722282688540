import React, { useEffect, useState } from "react";
import playlist from "../../assets/images/playlist.png";
import { Box, Button, Typography, Slider } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { PauseCircleFilled } from "@mui/icons-material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
function Cards(props) {
  const [audio] = useState(new Audio(props.src));
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [position, setPosition] = useState(0);
  useEffect(() => {
    // Add event listeners for audio playback
    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);
    const handleTimeUpdate = () => setCurrentTime(audio.currentTime);
    const handleLoadedMetadata = () => setDuration(audio.duration);

    audio.addEventListener("play", handlePlay);
    audio.addEventListener("pause", handlePause);
    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("loadedmetadata", handleLoadedMetadata);
    console.log(props.src);
    // Remove event listeners to prevent memory leaks
    return () => {
      audio.removeEventListener("play", handlePlay);
      audio.removeEventListener("pause", handlePause);
      audio.removeEventListener("timeupdate", handleTimeUpdate);
      audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
    };
  }, [audio]);
  useEffect(() => {
    // Check if audio is ready to play
    if (audio.readyState >= 2) {
      console.log("ready");
      // Start playing only if audio is ready
      if (isPlaying) {
        audio.play();
      } else {
        audio.pause();
      }
    }
  }, [isPlaying, audio]);
  const playPauseToggle = () => {
    setIsPlaying(!isPlaying);
  };
  return (
    <Box
      sx={{ display: "flex", flexDirection: "row", gap: "1rem", width: "100%" }}
    >
      <img
        style={{ width: 80, height: 80 }}
        className="myClass"
        src={props.img}
      ></img>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
          width: "100%",
        }}
      >
        {isPlaying ? (
          <PauseCircleFilled
            onClick={playPauseToggle}
            sx={{
              width: 35,
              height: 35,
              color: "white",
              backgroundColor: "#FEA10B",
              borderRadius: "50%",
            }}
          />
        ) : (
          <PlayArrowIcon
            onClick={playPauseToggle}
            sx={{
              width: 35,
              height: 35,
              color: "white",
              backgroundColor: "#FEA10B",
              borderRadius: "50%",
            }}
          />
        )}
        {/* <Typography
            sx={{
              color: "white",
              marginLeft: 1,
              fontSize: 25,
              fontWeight: "900",
            }}
          >
            Beats Title Name
          </Typography> */}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            // alignItems: "center",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: "black",
              textAlign: "left",
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            {props.title}
          </Typography>
          <Slider
            size="medium"
            max="1"
            value={currentTime / duration || 0}
            step="0.01"
            onChange={(_, value) => setPosition(value)}
            sx={{
              color: "#FEA10B",
              marginLeft: "10px",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "#ACB3BA",
                marginLeft: 1,
                fontSize: 14,
                fontWeight: "300",
                marginLeft: 2,
              }}
            >
              5:12 PM
            </Typography>
            <Typography
              sx={{
                color: "#ACB3BA",

                fontSize: 14,
                fontWeight: "300",
              }}
            >
              {props.title}
            </Typography>
          </Box>
        </Box>

        <Typography
          sx={{
            color: "#ACB3BA",
            textAlign: "right",
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          $56
        </Typography>
      </Box>
    </Box>
  );
}

export default Cards;
