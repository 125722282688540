import axios from "axios";

export const getProductsWithPangination = async (offset) => {
  let sortedData = {};
  try {
    const response = await axios.get(
      `http://172.16.100.109:3002/api/products?limit=12&offset=${offset}`
    ); // Replace with your API endpoint

    sortedData = response.data;
  } catch (error) {
    // Handle errors here
    console.error("Error fetching data:", error);
  }
  return sortedData;
};
