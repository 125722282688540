import React from "react";
import { Box, Typography } from "@mui/material";
function PlayCards({ src, text }) {
  return (
    <Box
      sx={{
        width: 100,
        height: 100,
      }}
    >
      <img
        style={{
          width: "120px",
          height: "120px",

          borderRadius: 10,
        }}
        src={src}
      />

      <Typography
        sx={{
          display: "flex",

          justifyContent: "center",
          color: "black",
          fontWeight: "800",
          fontSize: 15,
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}

export default PlayCards;
