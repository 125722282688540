import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Container, ListItem } from "@mui/material";
import logo from "../../assets/images/logo.png";
import SearchBar from "../../components/searchbar/Serachbar";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCart } from "../../slices/cartSlice";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";

const Header = ({ active, setActive }) => {
  const styles = {
    justi: {
      display: { lg: "flex", xs: "none" },
      justifyContent: { xl: "center", md: "center" },
      width: "100%",
    },
    user: {
      padding: "0.4rem",
      width: "35px",
      height: "35px",
      backgroundColor: "#fda10b",
      display: "flex",
      alignItems: "center",

      borderRadius: "50%",
      color: "#fff",
      justifyContent: "center",
    },
    navigation: {
      backgroundColor: "inherit",
      border: "none",
      boxShadow: "none",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      ul: {
        padding: "0px",
        width: "90%",
        margin: "0 0 0 auto",
        display: "flex",
        alignItems: "center",
      },
      li: {
        display: "inline",
        textAlign: "center",
        padding: "0px",
      },
      a: {
        color: "#fff",
        display: "inline",
        textAlign: "center",
        textDecoration: "none",
        cursor: "pointer",
        fontSize: 18,
      },
    },
    headerlogo: {
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    padd0: {
      padding: 0,
    },
    Mainheader: {
      width: "100%",
    },
    paddTop: {},
    carticon: {
      marginLeft: "15px",
      padding: "0.4rem",
      width: "35px",
      height: "35px",
      backgroundColor: "#fda10b",
      display: "flex",
      alignItems: "center",
      marginRight: "5px",
      borderRadius: "50%",
      color: "#fff",
      justifyContent: "center",
    },
    cartsearch: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "50px",
      gap: "0.5rem",
      width: "100%",
    },
    mainheader: {
      width: "100%",

      marginTop: "20px",
    },
    headerContainer: {
      textAlign: "center",
    },
    cart: {
      a: {
        backgroundColor: "#eb593b",
      },
    },
    bedge: {
      position: "absolute",
      width: 20,
      height: 20,
      bgcolor: "#bd2222",
      top: -5,
      right: 0,

      borderRadius: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
      border: "2px solid white",
      backgroundColor: "#d6b683",
    },
    link: {
      position: "relative",

      padding: "4px 0",
      margin: "0 10px",
      textDecoration: "none",
      color: "white",
      transition: "color 0.3s, border-bottom-color 0.3s",
      fontSize: "1.3rem",
      "&:hover": {
        color: "white",
        borderBottomColor: "white",
      },
      "&::before": {
        content: '""',
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        borderBottom: `2px solid white`,
        transform: "scaleX(0)",
        transformOrigin: "center",
        transition: "transform 0.3s",
      },
      "&:hover::before": {
        transform: "scaleX(1)",
      },
    },
    response: {
      maxWidth: { lg: "1500px", md: "1200px" },
      position: "relative",
    },
    navItems: {
      transition: "all ease-in-out 0.5s",
      ":hover": {
        color: "#F89708",
        transform: "scale(1.05)",
      },
    },
  };
  const cart = useSelector(selectCart);
  const lengthCart = cart.length;
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        backgroundColor: "#0F0F0F",
        color: "white",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {["Home", "Music", "Album", "Blogs"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton sx={styles.navItems}>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {["Events", "Videos", "Store", "Pricing", "Contact"].map(
          (text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton sx={styles.navItems}>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
    </Box>
  );
  useEffect(() => {
    console.log(cart, "cart");
  }, []);
  return (
    <>
      <Container sx={styles.response}>
        <Box sx={styles.mainheader}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              lg={12}
              xl={9}
              alignItems="start"
              justifyContent="center"
            >
              <Grid container sx={styles.justi}>
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <Link
                    to="/"
                    style={{
                      textDecoration: "none",
                      listStyle: "none",
                    }}
                  >
                    <ListItem
                      onClick={() => setActive("Home")}
                      sx={{ paddingX: "4px !important" }}
                    >
                      <Typography
                        sx={[
                          styles.link,
                          { color: active === "Home" ? "#fda10b" : "white" },
                        ]}
                      >
                        Home
                      </Typography>
                    </ListItem>
                  </Link>
                </Grid>
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <Link
                    to="/music"
                    style={{
                      textDecoration: "none",
                      listStyle: "none",
                    }}
                  >
                    <ListItem
                      onClick={() => setActive("Music")}
                      sx={{ paddingX: "4px !important" }}
                    >
                      <Typography
                        sx={[
                          styles.link,
                          { color: active === "Music" ? "#fda10b" : "white" },
                        ]}
                      >
                        Music
                      </Typography>
                    </ListItem>
                  </Link>
                </Grid>
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <Link
                    to="/album"
                    style={{
                      textDecoration: "none",
                      listStyle: "none",
                    }}
                  >
                    <ListItem
                      onClick={() => setActive("Album")}
                      sx={{ paddingX: "4px !important" }}
                    >
                      <Typography
                        sx={[
                          styles.link,
                          { color: active === "Album" ? "#fda10b" : "white" },
                        ]}
                      >
                        Albums
                      </Typography>
                    </ListItem>
                  </Link>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Link
                    to="/events"
                    style={{
                      textDecoration: "none",
                      listStyle: "none",
                    }}
                  >
                    <ListItem
                      onClick={() => setActive("Events")}
                      sx={{ paddingX: "4px !important" }}
                    >
                      <Typography
                        sx={[
                          styles.link,
                          { color: active === "Events" ? "#fda10b" : "white" },
                        ]}
                      >
                        Events
                      </Typography>
                    </ListItem>
                  </Link>
                </Grid>
                <Grid item lg={1} md={2} sx={{ marginX: "2.5rem" }}>
                  <Link to="/">
                    <ListItem sx={styles.headerlogo}>
                      <img src={logo} alt="logo" width={250} height={150} />
                    </ListItem>
                  </Link>
                </Grid>
                <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Link
                    to="/video"
                    style={{
                      textDecoration: "none",
                      listStyle: "none",
                    }}
                  >
                    <ListItem
                      onClick={() => setActive("Video")}
                      sx={{ paddingX: "4px !important" }}
                    >
                      <Typography
                        sx={[
                          styles.link,
                          { color: active === "Video" ? "#fda10b" : "white" },
                        ]}
                      >
                        Videos
                      </Typography>
                    </ListItem>
                  </Link>
                </Grid>
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <Link
                    to="/store"
                    style={{
                      textDecoration: "none",
                      listStyle: "none",
                    }}
                  >
                    <ListItem
                      onClick={() => setActive("Store")}
                      sx={{ paddingX: "4px !important" }}
                    >
                      <Typography
                        sx={[
                          styles.link,
                          { color: active === "Store" ? "#fda10b" : "white" },
                        ]}
                      >
                        Store
                      </Typography>
                    </ListItem>
                  </Link>
                </Grid>
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <Link
                    to="/blogs"
                    style={{
                      textDecoration: "none",
                      listStyle: "none",
                    }}
                  >
                    <ListItem
                      onClick={() => setActive("Blogs")}
                      sx={{ paddingX: "4px !important" }}
                    >
                      <Typography
                        sx={[
                          styles.link,
                          { color: active === "Blogs" ? "#fda10b" : "white" },
                        ]}
                      >
                        Blogs
                      </Typography>
                    </ListItem>
                  </Link>
                </Grid>
                <Grid item sx={{ display: "flex", alignItems: "center" }}>
                  <Link
                    to="/contact"
                    style={{
                      textDecoration: "none",
                      listStyle: "none",
                    }}
                  >
                    <ListItem
                      onClick={() => setActive("Contact")}
                      sx={{ paddingX: "4px !important" }}
                    >
                      <Typography
                        sx={[
                          styles.link,
                          { color: active === "Contact" ? "#fda10b" : "white" },
                        ]}
                      >
                        Contact
                      </Typography>
                    </ListItem>
                  </Link>
                </Grid>
              </Grid>
              <Box
                fontSize={"large"}
                sx={{
                  display: { lg: "none !important", xs: "flex" },
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "20px",
                  color: "white",
                  justifyContent: "center",
                }}
              >
                <MenuIcon
                  onClick={toggleDrawer("right", true)}
                  sx={{ cursor: "pointer" }}
                />
                <img src={logo} alt="logo" width={150} height={150} />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={12}
              xl={3}
              sx={{
                display: "flex",
                alignItems: { md: "start", xs: "center" },
                justifyContent: "center",
              }}
            >
              <Box sx={styles.cartsearch}>
                <SearchBar />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "0.5rem",
                  }}
                >
                  <Link to="/login">
                    <PersonOutlineIcon fontSize="medium" sx={styles.user} />
                  </Link>

                  <Link
                    to="/add-to-cart"
                    style={{
                      textDecoration: "none",
                      listStyle: "none",
                    }}
                  >
                    <Box sx={{ position: "relative" }}>
                      <ShoppingCartIcon
                        fontSize="medium"
                        sx={styles.carticon}
                      />
                      <Typography sx={styles.bedge}>{lengthCart}</Typography>
                    </Box>
                  </Link>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        {list("right")}
      </Drawer>
    </>
  );
};

export default Header;
