import React, { useEffect, useState } from "react";
import Carousel from "react-simply-carousel";
import car1 from "../../assets/images/car01.png";
import car2 from "../../assets/images/car02.png";
import car3 from "../../assets/images/car03.png";
import { Box, Skeleton, Typography } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import { useNavigate } from "react-router";
import { getAlbum } from "../../services/Album";
function Carousels() {
  const [activeSlide, setActiveSlide] = useState(0);
  const [album, setAlbum] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getAlbum().then((data) => {
      setAlbum(data);
    });
  }, []);
  return (
    <div style={{ width: "100%", height: "100%", marginTop: "1.5rem" }}>
      <Carousel
        containerProps={{
          style: {
            width: "100%",
            justifyContent: "space-between",
            userSelect: "none",
          },
        }}
        swipeTreshold={20}
        activeSlideIndex={activeSlide}
        activeSlideProps={{}}
        onRequestChange={setActiveSlide}
        forwardBtnProps={{
          children: (
            <EastIcon
              sx={{
                fontSize: "4rem",
                color: "white",
                transition: "all ease-in-out 0.5s",

                ":hover": {
                  transform: "scale(1.5)",
                  color: "#FEA10C",
                },
              }}
            />
          ),
          style: {
            width: 60,
            height: 60,
            minWidth: 60,
            position: "relative",
            top: "-100px",
            border: "none",
            backgroundColor: "transparent",
          },
        }}
        backwardBtnProps={{
          children: (
            <WestIcon
              sx={{
                fontSize: "4rem",
                color: "white",
                transition: "all ease-in-out 0.5s",
                ":hover": {
                  transform: "scale(1.5)",
                  color: "#FEA10C",
                },
              }}
            />
          ),
          style: {
            width: 60,
            height: 60,
            minWidth: 60,
            alignSelf: "center",
            position: "relative",
            left: "89%",
            top: "-270px",
            zIndex: 99,
            border: "none",
            backgroundColor: "transparent",
            display: "flex",
          },
        }}
        dotsNav={{
          show: false,
          itemBtnProps: {
            style: {
              height: 16,
              width: 16,
              borderRadius: "50%",
              border: 0,
            },
          },
          activeItemBtnProps: {
            style: {
              height: 16,
              width: 16,
              borderRadius: "50%",
              border: 0,
              background: "#404041",
            },
          },
        }}
        itemsToShow={5}
        speed={400}
        infinite={true}
      >
        {album && album.length > 0
          ? album?.map((item, index) => {
              return (
                <Box
                  onClick={() => navigate(`/music/${item.albumName}`)}
                  sx={{
                    width: "400px",
                    height: "400px",
                    marginX: "8rem",
                    borderRadius: "1rem",
                    display: "flex",
                    flexDirection: "column",
                    transition: "all ease-in-out 0.5s",
                    transform: "scale(0.9)",

                    ":hover": {
                      transform: "scale(1)",
                    },
                  }}
                  key={index}
                >
                  <Box
                    sx={{
                      width: "350px",
                      height: "350px",
                      borderRadius: "1rem",
                      backgroundColor: "transparent",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={item.image}
                      height="100%"
                      width="100%"
                      style={{
                        borderRadius: "1rem",
                        border: "2px solid #FDCB08",
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "1.5rem",
                      color: "white",
                      marginY: "1rem",
                      width: "100%",
                      textAlign: "center",
                      fontWeight: "700",
                    }}
                  >
                    {item.albumName}
                  </Typography>
                </Box>
              );
            })
          : [0, 0, 0].map((data) => {
              return (
                <>
                  <Skeleton
                    animation="wave"
                    sx={{
                      bgcolor: "grey",
                      marginX: "1rem",
                      borderRadius: "1rem",
                      opacity: 0.8,
                    }}
                    width={400}
                    height={500}
                  />
                </>
              );
            })}
      </Carousel>
    </div>
  );
}

export default Carousels;
