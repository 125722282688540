import React, { useState, useEffect } from "react";
import playlist from "../../assets/images/playlist.png";
import { Box, Button, Typography, Slider } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { PauseCircleFilled } from "@mui/icons-material";
import "./music.css";
import { motion } from "framer-motion";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
function PlaylistCard({ src, songName, onClick, image, desc, dura, price }) {
  const [audio] = useState(new Audio(src));
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(0.5);
  const [position, setPosition] = useState(0);
  useEffect(() => {
    // Add event listeners for audio playback
    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);
    const handleTimeUpdate = () => setCurrentTime(audio.currentTime);
    const handleLoadedMetadata = () => setDuration(audio.duration);

    audio.addEventListener("play", handlePlay);
    audio.addEventListener("pause", handlePause);
    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("loadedmetadata", handleLoadedMetadata);
    console.log(src);
    // Remove event listeners to prevent memory leaks
    return () => {
      audio.removeEventListener("play", handlePlay);
      audio.removeEventListener("pause", handlePause);
      audio.removeEventListener("timeupdate", handleTimeUpdate);
      audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
    };
  }, [audio]);
  const downloadAudio = async () => {
    try {
      const audioUrl = src;

      // Extract the filename from the URL
      const urlParts = audioUrl.split("/");
      const filename = decodeURIComponent(urlParts[urlParts.length - 1]);

      const response = await fetch(audioUrl);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = filename; // Set the download filename dynamically
      a.click();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading audio:", error);
    }
  };
  useEffect(() => {
    // Check if audio is ready to play
    if (audio.readyState >= 2) {
      console.log("ready");
      // Start playing only if audio is ready
      if (isPlaying) {
        audio.play();
      } else {
        audio.pause();
      }
    }
  }, [isPlaying, audio]);
  const playPauseToggle = () => {
    setIsPlaying(!isPlaying);
  };

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);
    audio.volume = newVolume;
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };
  return (
    <motion.div
      initial={{ x: -40, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ type: "spring", bounce: 0.5, delay: 1 }}
      style={{ width: "100%" }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 2,
          width: "100%",
        }}
      >
        <img
          style={{ width: 200, height: 200 }}
          className="myClass"
          src={image}
        ></img>

        <Box sx={{ marginX: 2, width: "100%" }} className="musicList">
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            {isPlaying ? (
              <PauseCircleFilled
                onClick={playPauseToggle}
                sx={{
                  width: 35,
                  height: 35,
                  color: "white",
                  backgroundColor: "#FEA10B",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <PlayArrowIcon
                onClick={playPauseToggle}
                sx={{
                  width: 35,
                  height: 35,
                  color: "white",
                  backgroundColor: "#FEA10B",
                  borderRadius: "50%",
                }}
              />
            )}

            <Typography
              sx={{
                color: "white",
                marginLeft: 1,
                fontSize: 25,
                fontWeight: "900",
              }}
            >
              {songName}
            </Typography>
          </Box>

          {/* <Typography
          sx={{
            color: "#ACB3BA",
            marginLeft: 1,
            fontSize: 18,
            marginY: 2,
          }}
        >
          Dreamy
        </Typography> */}
          <Slider
            size="medium"
            max="1"
            value={currentTime / duration || 0}
            step="0.01"
            onChange={(_, value) => setPosition(value)}
            sx={{
              color: "#FEA10B",
              marginLeft: "1rem",
              marginTop: "0.5rem",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginY: 1,
            }}
          >
            <Typography
              sx={{
                color: "white",
                marginLeft: 1,
                fontSize: 14,
              }}
            >
              {dura}
            </Typography>
            <Typography
              sx={{
                color: "#ACB3BA",
                marginLeft: 1,
                fontSize: 14,
                fontWeight: "300",
                marginLeft: 2,
              }}
            >
              $ {price}
            </Typography>
          </Box>
          <Typography
            sx={{
              color: "#ACB3BA",
              marginLeft: 1,
              fontSize: 18,
              marginY: 1,
            }}
          >
            {desc}
          </Typography>
          <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <Button sx={{ color: "white", marginX: 2 }} onClick={onClick}>
              Calm
            </Button>
            <Button
              sx={{
                color: "white",
                marginX: 2,
                backgroundColor: "#FCA10C",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={downloadAudio}
            >
              <FileDownloadOutlinedIcon />
              Download
            </Button>
            <Button sx={{ color: "white", marginX: 2 }}>Share</Button>
          </Box>
        </Box>
      </Box>
    </motion.div>
  );
}

export default PlaylistCard;
