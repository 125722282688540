import { Skeleton, Typography } from "@mui/material";
import React from "react";
import { Box } from "@mui/material";
import { motion } from "framer-motion";
function MusicCard({ src, text, active, setActiveAlbum, index }) {
  return (
    <motion.div style={{ margin: 5 }} onClick={() => setActiveAlbum(text)}>
      <Box
        marginX={2}
        sx={{
          width: "100%",
          height: "100%",
          transition: "all ease-in-out 0.5s",
          ":hover": {
            transform: "scale(1.1)",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "180px",
            height: "180px",
          }}
        >
          <img
            loading="lazy"
            style={{
              width: "100%",
              height: "100%",
              borderWidth: 5,
              borderColor: active === text ? "#b47512" : "transparent",
              borderStyle: "solid",
              borderRadius: 20,
            }}
            src={src}
          ></img>
        </Box>
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontWeight: "800",
            fontSize: 20,
            marginY: "1rem",
            fontStyle: "italic",
            width: "100%",
          }}
        >
          {text}
        </Typography>
      </Box>
    </motion.div>
  );
}

export default MusicCard;
