import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: [],
  reducers: {
    addToCart: (state, action) => {
      const productToAdd = action.payload;
      const existingProductIndex = state.findIndex(
        (product) => product.details[0].title === productToAdd.details[0].title
      );

      if (existingProductIndex !== -1) {
        // If the product with the same title exists, create a new product with quantity 1
        state[existingProductIndex].quantity += 1;
      } else {
        // If it doesn't exist, increment its quantity
        state.push({ ...productToAdd, quantity: 1 });
      }
    },
    increaseQuantity: (state, action) => {
      const { title } = action.payload;
      const product = state.find(
        (product) => product.details[0].title === title
      );
      if (product) {
        product.quantity += 1;
      }
    },
    decreaseQuantity: (state, action) => {
      const { title } = action.payload;
      const productIndex = state.findIndex(
        (product) => product.details[0].title === title
      );

      if (productIndex !== -1) {
        // Decrease the quantity
        state[productIndex].quantity -= 1;

        // Check if the quantity is less than 1, and if so, remove the product
        if (state[productIndex].quantity < 1) {
          state.splice(productIndex, 1);
        }
      }
    },
  },
});

export const { addToCart, increaseQuantity, decreaseQuantity } =
  cartSlice.actions;
export const selectCart = (state) => state.cart;
export default cartSlice.reducer;
