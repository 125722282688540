import { Box, Container, Typography } from "@mui/material";
import React from "react";

import Carousels from "./Carousel";
import background from "../../assets/images/background.png";
import Layout from "../Layout/Layout";

function Album({ active, setActive }) {
  const style = {
    container: {
      maxWidth: { lg: "1450px", md: "1400px" },
      paddingTop: "5rem",
    },
    mainHeading: {
      textAlign: "center",
      fontWeight: 900,
      color: "white",
    },
    headingBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    main: {
      dsiplay: "block",
    },
    innbackground: {
      position: "absolute",
      top: "0px",
      width: "100%",
      backgroundImage: `url(${background})`,
      minHeight: "100vh",
    },
  };
  return (
    <>
      <Box sx={style.innbackground}>
        <Layout active={active} setActive={setActive}>
          <Box sx={style.box}>
            <Container sx={style.container}>
              <Box sx={style.main}>
                <Box sx={style.headingBox}>
                  <Typography variant="h3" sx={style.mainHeading}>
                    MUSIC ALBUM
                  </Typography>
                </Box>
                <Carousels />
              </Box>
            </Container>
          </Box>
        </Layout>
      </Box>
    </>
  );
}

export default Album;
