import React, { useEffect, useState } from "react";

import MusicCard from "./MusicCard";
import { Box, Container, Grid, Skeleton } from "@mui/material";
import beats from "../../assets/images/beats.png";
import calm from "../../assets/images/calm.png";
import romantic from "../../assets/images/romantic.png";
import jazz from "../../assets/images/jazz.png";
import beatseletronic from "../../assets/images/beatseletronic.png";
import vBack from "../../assets/images/vBack.jpg";
import beatsspring from "../../assets/images/beatsspring.png";
import PlaylistCard from "./PlaylistCard";
import Layout from "../Layout/Layout";
import { motion } from "framer-motion";
import { addMusicInUser, getMusics } from "../../services/Music";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAlbum } from "../../services/Album";
import { sendFinance } from "../../services/Finance";
import { AddCountInMusic } from "../../services/Music";
import { useParams } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
function Music({ active, setActive }) {
  const auth = getAuth();
  const navigate = useNavigate();
  const [activeAlbum, setActiveAlbum] = useState("Music");
  const [allData, setAllData] = useState();
  const userState = useSelector((state) => state.user.user);
  const style = {
    container: {
      maxWidth: { lg: "1450px", md: "1200px" },
    },
  };
  const music = [
    {
      src: "https://firebasestorage.googleapis.com/v0/b/bizproque.appspot.com/o/music%2FShake%20Dat%20Gluteus.wav?alt=media&token=2cd8f570-9a17-406b-b5a3-a909a8e00fd5",
      title: "Shake Dat Gluteus",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/bizproque.appspot.com/o/music%2FResusitate%202.wav?alt=media&token=1f40ebb8-1c78-4f2e-a469-657ca6fa8a33",
      title: "Resusitate 2",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/bizproque.appspot.com/o/music%2FPeace%20And%20Discontent.wav?alt=media&token=c707f85c-79db-492a-bc74-c6604b92254a",
      title: "Peace And Discontent",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/bizproque.appspot.com/o/music%2FLying%20In%20Wait.wav?alt=media&token=c9baa713-bc38-4df8-bd47-742b1db4b4ff",
      title: "Lying In Wait",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/bizproque.appspot.com/o/music%2FKill%20Em%20With%20Kindness.wav?alt=media&token=e6a7851b-6b37-41e4-9879-e43b7c406aac",
      title: "Kill With Kindness",
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/bizproque.appspot.com/o/music%2FBe%20More%202.wav?alt=media&token=848e39fc-7330-4746-b788-78c300041edb",
      title: "Be More",
    },
  ];
  const data = [
    {
      image: beats,
      text: "Beats",
    },
    {
      image: calm,
      text: "Calm",
    },
    {
      image: romantic,
      text: "Romantic",
    },
    {
      image: jazz,
      text: "Jazz",
    },
    {
      image: beatseletronic,
      text: "Beats Eletronic",
    },
    {
      image: beatsspring,
      text: "Beats Spring",
    },
  ];
  const [album, setAlbums] = useState();
  const addMusicInUserDoc = (item) => {
    AddCountInMusic(item).then((data) => {});
    const currentDate = new Date();
    const currentMonth = (currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0"); // Months are zero-based, so add 1.
    const currentDay = currentDate.getDate().toString().padStart(2, "0");
    const currentYear = currentDate.getFullYear();

    const formattedDate = `${currentMonth}/${currentDay}/${currentYear}`;
    if (userState) {
      addMusicInUser(item)
        .then(() => {
          console.log("data sent");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          toast.success("Your Music Added succesfully");
        });
    } else {
      toast.error("You have to Login first");
      navigate("/login");
    }

    const body = {
      user: auth.currentUser.email,
      userName: auth.currentUser.displayName,
      music: item,
      date: formattedDate,
    };
    sendFinance(body).then((data) => {
      console.log(" data send to finance");
    });
  };
  useEffect(() => {
    setActive("Music");
    getMusics().then((data) => {
      setAllData(data);
    });
    getAlbum().then((data) => {
      setAlbums(data);
    });
  }, []);

  const params = useParams();
  console.log(params, "params");
  useEffect(() => {
    if (params.album) setActiveAlbum(params.album);
  }, [params]);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          position: "absolute",
          background: `url(${vBack}) no-repeat`,
          backgroundSize: "cover",
        }}
      >
        <Layout active={active} setActive={setActive}>
          <Container sx={style.container}>
            <Grid container rowSpacing={2} columnSpacing={2}>
              {album && album.length > 0 ? (
                album?.map((item, index) => (
                  <Grid item lg={2} md={3}>
                    <motion.div
                      style={{
                        width: "100%",
                        height: "100%",
                        marginY: "12px",
                      }}
                      initial={{ y: -60 * index, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ duration: 2 }}
                    >
                      <MusicCard
                        active={activeAlbum}
                        src={item.image}
                        text={item.albumName}
                        key={index}
                        setActiveAlbum={setActiveAlbum}
                        index={index}
                      ></MusicCard>
                    </motion.div>
                  </Grid>
                ))
              ) : (
                <>
                  <Grid item lg={2} md={3} key={data}>
                    <Skeleton
                      sx={{
                        bgcolor: "grey",
                        opacity: 0.6,
                        borderRadius: "15px",
                        marginY: "12px",
                      }}
                      animation="wave"
                      variant="rectangular"
                      width={170}
                      height={170}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                width: "100%",
                paddingBottom: "5rem",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "black",
                  width: "100%",
                  borderRadius: 5,
                  alignItems: "flex-start",
                  display: "flex",
                  padding: 3,
                  flexDirection: "column",
                  overflowY: "scroll",
                  position: "relative",
                  height: 500,
                  zIndex: 8,
                  " &::-webkit-scrollbar": {
                    position: "relative",
                    zIndex: 99,
                    width: "20px",
                    backgroundColor: "transparent",
                    marginX: "0.5rem",
                    minHeight: "20px",
                    maxHeight: "30px",
                  },
                  "&::-webkit-scrollbar-track": {
                    position: "relative",
                    zIndex: 99,
                    backgroundColor: "transparent",
                    marginX: "0.5rem",
                    borderRadius: "50%",
                  },
                  "&::-webkit-scrollbar-thumb ": {
                    position: "relative",
                    zIndex: 99,
                    backgroundColor: "#b47512",
                    borderRadius: "550px",
                    height: "2px !important",
                    border: "8px solid #6F490A",
                    margin: "0.2rem",
                  },
                }}
              >
                {allData && allData.length > 0
                  ? allData?.map((item, index) => {
                      return (
                        <PlaylistCard
                          src={item.audio}
                          songName={item.albumName}
                          image={item.image}
                          desc={item.description}
                          dura={item.Duration}
                          key={index}
                          price={item.price}
                          onClick={() => addMusicInUserDoc(item)}
                        />
                      );
                    })
                  : ["1", "2"].map((data, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: "12px",
                              padding: "1rem",
                            }}
                          >
                            <Skeleton
                              sx={{
                                bgcolor: "grey",
                                opacity: 0.6,
                                borderRadius: "15px",
                                marginY: "12px",
                              }}
                              animation="wave"
                              variant="rectangular"
                              width={170}
                              height={170}
                            />
                            <Box>
                              <Skeleton
                                sx={{
                                  bgcolor: "grey",
                                  opacity: 0.6,
                                  borderRadius: "15px",
                                  marginY: "12px",
                                }}
                                animation="wave"
                                variant="rectangular"
                                width={600}
                                height={30}
                              />
                              <Skeleton
                                sx={{
                                  bgcolor: "grey",
                                  opacity: 0.6,
                                  borderRadius: "15px",
                                  marginY: "12px",
                                }}
                                animation="wave"
                                variant="rectangular"
                                width={450}
                                height={20}
                              />
                            </Box>
                          </Box>
                        </React.Fragment>
                      );
                    })}
              </Box>
            </Box>
          </Container>
        </Layout>
      </Box>
      <ToastContainer />
    </>
  );
}

export default Music;
