import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import Layout from "../Layout/Layout";
import vBack from "../../assets/images/vBack.jpg";
import b1 from "../../assets/images/b1.jpg";
import b2 from "../../assets/images/b2.jpg";
import b3 from "../../assets/images/b3.jpg";
import b4 from "../../assets/images/b4.jpg";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PlaceIcon from "@mui/icons-material/Place";
import "./events.css";
import { getEvents } from "../../services/Events";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";

function Events({ active, setActive }) {
  const navigate = useNavigate();
  const [events, setEvents] = useState();
  const data = [
    {
      img: b2,
    },
    {
      img: b1,
    },
    {
      img: b4,
    },
  ];
  const redirectDetailsPage = (page) => {
    console.log(page, "page");
    navigate(`/event-details/${page}`);
  };
  useEffect(() => {
    getEvents().then((data) => {
      console.log(data);
      setEvents(data);
    });
  }, []);
  return (
    <>
      <Box sx={style.main}>
        <Layout
          active={active ? active : null}
          setActive={setActive ? setActive : null}
        >
          <Container sx={style.Container}>
            <Typography sx={style.Headings}>EVENTS</Typography>
            <Grid container columnSpacing={4}>
              {events &&
                events.map((item) => {
                  const options = {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    timeZoneName: "short",
                  };
                  const formattedDate = new Date(
                    item.date * 1000
                  ).toLocaleDateString(undefined, options);

                  let month = formattedDate.split(" ")[0];
                  let day = formattedDate.split(" ")[1];
                  let year = formattedDate.split(" ")[2];
                  let time = formattedDate.split(", ")[1];

                  return (
                    <Grid item lg={4}>
                      <Box
                        sx={style.cardBox}
                        onClick={() => redirectDetailsPage(item.id)}
                      >
                        <motion.div
                          initial={{ opacity: 0, scale: 0.5 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{
                            duration: 1.5,
                            delay: 0.5,
                            ease: [0, 0.71, 0.2, 1.01],
                          }}
                        >
                          <img
                            src={item.image}
                            className="imgHover"
                            alt="oops"
                            style={{ width: "400px", height: "250px" }}
                          />
                        </motion.div>
                        <Box sx={style.cardBody}>
                          <Box sx={style.monthDateBox}>
                            <motion.div
                              initial={{ opacity: 0, scale: 0.5 }}
                              animate={{ opacity: 1, scale: 1 }}
                              transition={{
                                duration: 1.5,
                                delay: 0.5,
                                ease: [0, 0.71, 0.2, 1.01],
                              }}
                            >
                              <Box sx={style.gradientBox}>
                                <Typography sx={style.numberfont}>
                                  {day.slice(0, -1)}
                                </Typography>
                              </Box>
                            </motion.div>
                            <Typography sx={style.numberfont1}>
                              {month}
                            </Typography>
                          </Box>
                          <Box sx={style.rightSide}>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: 600, color: "white" }}
                            >
                              {item.name}
                            </Typography>
                            <Box sx={style.rightToRight}>
                              <Box
                                sx={{
                                  display: "flex",
                                  gap: "0.3rem",
                                  flexDirection: "row",
                                }}
                              >
                                <AccessTimeIcon sx={{ color: "white" }} />
                                <Typography sx={{ color: "white" }}>
                                  {item.time}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",

                                  flexDirection: "row",
                                }}
                              >
                                <PlaceIcon sx={{ color: "white" }} />
                                <Typography sx={{ color: "white" }}>
                                  {item.location}
                                </Typography>
                              </Box>
                            </Box>
                            <Typography
                              sx={{
                                fontSize: "0.9rem",
                                color: "white",
                                marginTop: "0.5rem",

                                marginBottom: "1.5rem",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",

                                maxWidth: "250px",
                              }}
                            >
                              {item.description}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  );
                })}
            </Grid>
          </Container>
        </Layout>
      </Box>
    </>
  );
}

export default Events;
const style = {
  gradientBox: {
    background: " #FDC830" /* fallback for old browsers */,
    background:
      "-webkit-linear-gradient(135deg, #F37335, #FDC830)" /* Chrome 10-25, Safari 5.1-6 */,
    background:
      "linear-gradient(135deg, #F37335, #FDC830)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */,
  },
  main: {
    width: "100%",
    position: "absolute",
    background: `url(${vBack}) no-repeat`,
    backgroundSize: "cover",
    minHeight: "100vh",
  },
  Container: {
    maxWidth: { lg: "1500px", md: "1200px" },
  },
  Headings: {
    fontSize: "3rem",
    fontWeight: 800,
    color: "white",
  },
  cardBox: {
    width: "100%",
  },
  cardBody: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    padding: "1rem",
  },
  numberfont: {
    color: "white",
    fontWeight: 800,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "2rem",
    padding: "1rem",
  },
  numberfont1: {
    color: "white",
    fontWeight: 800,
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    fontSize: "2rem",
  },
  rightSide: {
    paddingX: "0.5rem",
  },
  rightToRight: {
    display: "flex",
    flexDirection: "row",
    gaap: "1rem",
    justifyContent: "space-between",
  },
};
