import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  signOut,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../firebaseConfig";
const auth = getAuth();

export const signUp = async (email, password, name) => {
  return await createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      addDoc(collection(db, "users"), {
        name,
        email: email?.trim(),
        createdAt: serverTimestamp(),
      });

      updateProfile(userCredential.user, {
        displayName: name,
      });
      return userCredential.user;
    })
    .catch((error) => {
      console.log(error);
      const errorCode = error.code;
      const errorMessage = error.message;
    });
};
export const signIn = async (email, password, name) => {
  return signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      updateProfile(userCredential.user, {
        displayName: name,
      });
      return userCredential.user;
    })
    .catch((error) => {
      console.log(error);
      const errorCode = error.code;
      const errorMessage = error.message;
    });
};

export const logOut = async () => await signOut(auth);

export const forgetPasswordUser = async (email) => {
  sendPasswordResetEmail(auth, email)
    .then(() => {
      console.log("reset email sent");
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ..
    });
};
