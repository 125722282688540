import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import vBack from "../../assets/images/vBack.jpg";
import { getSpecificEvent } from "../../services/Events";
import { useParams } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PlaceIcon from "@mui/icons-material/Place";

function EventDetails({ active, setActive }) {
  const { id } = useParams();
  const [detail, setDetails] = useState();
  console.log(id);
  useEffect(() => {
    getSpecificEvent(id).then((data) => {
      console.log(data, "specific event");
      setDetails(data);
    });
  }, []);
  return (
    <>
      <Box sx={style.main}>
        <Layout
          active={active ? active : null}
          setActive={setActive ? setActive : null}
        >
          <Container sx={style.Container}>
            <Typography sx={style.Headings}>EVENT DETAILS</Typography>
            <Grid container columnSpacing={4}>
              <Grid item lg={6}>
                <Box sx={style.box}>
                  <img
                    src={detail?.image}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "2rem",
                    }}
                  />
                  <Box sx={style.absolute}>
                    <Typography sx={style.text}>24</Typography>
                    <Typography sx={style.text}>OCT</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6}>
                <Box sx={style.boxRight}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "0.3rem",
                      flexDirection: "row",
                      marginBottom: "12px",
                      alignItems: "center",
                    }}
                  >
                    <AccessTimeIcon sx={{ color: "white", fontSize: "30px" }} />
                    <Typography sx={{ color: "white", fontSize: "20px" }}>
                      {detail?.time}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",

                      flexDirection: "row",
                      marginBottom: "12px",
                      alignItems: "center",
                    }}
                  >
                    <PlaceIcon sx={{ color: "white", fontSize: "30px" }} />
                    <Typography sx={{ color: "white", fontSize: "20px" }}>
                      {detail?.location}
                    </Typography>
                  </Box>
                  <Typography
                    variant="h4"
                    sx={{ fontWeight: 600, color: "white", marginY: "15px" }}
                  >
                    {detail?.Name}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: "white",
                      marginY: "15px",
                      fontSize: "17px",
                      lineHeight: "30px",
                    }}
                  >
                    {detail?.description}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Layout>
      </Box>
    </>
  );
}

export default EventDetails;
const style = {
  main: {
    width: "100%",
    position: "absolute",
    background: `url(${vBack}) no-repeat`,
    backgroundSize: "cover",
  },
  Container: {
    maxWidth: { lg: "1500px", md: "1200px" },
  },
  Headings: {
    fontSize: "3rem",
    fontWeight: 800,
    color: "white",
  },
  box: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  absolute: {
    position: "absolute",
    right: 0,
    top: 50,
    width: "100px",
    height: "80px",
    background: "red",
    color: "white",
    display: "flex",
    flexDirection: "column",
    justfiyContent: "center",
    alignItems: "center",
    zIndex: 99,
    paddingTop: "10px",
    borderRadius: "5px",
    background: " #FDC830" /* fallback for old browsers */,
    background:
      "-webkit-linear-gradient(135deg, #F37335, #FDC830)" /* Chrome 10-25, Safari 5.1-6 */,
    background: "linear-gradient(135deg, #F37335, #FDC830)",
  },
  text: {
    fontWeight: 800,

    fontSize: "22px",
  },
  boxRight: {
    width: "100%",
    height: "100%",

    padding: "2rem",
  },
};
