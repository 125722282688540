import { Button, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
import product from "../../assets/images/d1.jpg";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectProductDetails } from "../../slices/productDetails";
import { addToCart } from "../../slices/cartSlice";
import { useParams } from "react-router-dom";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper/modules";

function ProductImage() {
  const { slug } = useParams();
  const cleanSlug = slug
    .toLowerCase()
    .replace(/[^\w\s-]/g, "") // Remove special characters
    .replace(/\s+/g, " ");
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const user = useSelector((state) => state.user.user);
  const productDetails = useSelector(selectProductDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const combinedData = {
    details: productDetails,
    quantity: quantity,
  };
  const handleCheckUser = () => {
    if (user) {
      dispatch(addToCart(combinedData));
      navigate("/add-to-cart");
    } else {
      dispatch(addToCart(combinedData));
      navigate("/login");
    }
  };
  const increment = () => {
    setQuantity(quantity + 1);
  };
  const deccrement = () => {
    if (quantity < 1) {
      console.log("you select minimum");
    } else {
      setQuantity(quantity - 1);
    }
  };
  useEffect(() => {
    console.log(combinedData, "combinedData");
  }, [quantity]);

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      {productDetails.map((item, index) => {
        return (
          <>
            <Box>
              <>
                <img
                  src={item.image}
                  style={{
                    width: "100%",
                    borderRadius: "1rem",
                  }}
                />
                <Swiper
                  style={{
                    width: 500,
                    height: 200,
                    marginTop: 20,
                    backgroundColor: "transparent",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                  loop={true}
                  spaceBetween={20}
                  slidesPerView={3}
                  className="mySwiper"
                  freeMode
                  modules={[Navigation, FreeMode]}
                >
                  <SwiperSlide
                    style={{
                      height: 190,
                      width: 150,

                      marginRight: 10,
                    }}
                  >
                    <img
                      src={product}
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: 10,
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide
                    style={{
                      height: 190,
                      width: 150,

                      marginRight: 10,
                    }}
                  >
                    <img
                      src={product}
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: 10,
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide
                    style={{
                      height: 190,
                      width: 150,

                      marginRight: 10,
                    }}
                  >
                    <img
                      src={product}
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: 10,
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide
                    style={{
                      height: 190,
                      width: 150,

                      marginRight: 10,
                    }}
                  >
                    <img
                      src={product}
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: 10,
                      }}
                    />
                  </SwiperSlide>
                  <SwiperSlide
                    style={{
                      height: 190,
                      width: 150,

                      marginRight: 10,
                    }}
                  >
                    <img
                      src={product}
                      style={{
                        height: "100%",
                        width: "100%",
                        borderRadius: 10,
                      }}
                    />
                  </SwiperSlide>
                </Swiper>
              </>
            </Box>
            <Box sx={{ marginX: 3 }}>
              <Typography
                sx={{ fontFamily: "BASSY", fontSize: "3rem", color: "white" }}
              >
                {item.title}
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.7rem",
                  marginBottom: 2,
                  fontWeight: "500",
                  color: "white",
                }}
              >
                Model: {item.model}
              </Typography>

              <Typography
                sx={{
                  fontSize: "1.7rem",
                  marginBottom: 2,
                  fontWeight: "500",
                  color: "white",
                }}
              >
                Type: {item.type}
              </Typography>
              <Typography
                sx={{
                  fontSize: "3rem",
                  marginBottom: 2,
                  fontWeight: "500",
                  color: "white",
                }}
              >
                $ 45.00
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {["orange", "white", "purple", "black", "red"].map((item) => {
                  return (
                    <Box
                      sx={{
                        height: 60,
                        width: 60,
                        borderRadius: 30,
                        backgroundColor: item,
                        marginLeft: 1,
                      }}
                    ></Box>
                  );
                })}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  border: "1px solid #fff",
                  width: 130,
                  borderRadius: 2,
                  padding: 1,
                  alignItems: "center",
                  marginY: 5,
                  justifyContent: "center",
                }}
              >
                <RemoveOutlinedIcon
                  onClick={deccrement}
                  sx={{
                    fontSize: "2rem",
                    marginRight: 3,
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: 2,
                  }}
                />
                <Typography
                  sx={{ fontSize: "1rem", fontWeight: "bold", color: "white" }}
                >
                  {quantity}
                </Typography>
                <AddOutlinedIcon
                  onClick={increment}
                  sx={{
                    fontSize: "2rem",
                    marginLeft: 3,
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: 2,
                  }}
                />
              </Box>
              <Typography
                sx={{ fontSize: "1rem", width: "70%", color: "white" }}
              >
                {item.description}
              </Typography>
              <Button
                sx={{
                  backgroundColor: "#F66900",
                  color: "white",
                  paddingX: 10,
                  paddingY: 2,
                  borderRadius: 30,
                  fontSize: "1.5rem",
                  marginY: 2,
                  ":hover": {
                    backgroundColor: "#F66900",
                    color: "white",
                    transform: "Scale(1.1) ",
                    transition: "all ease 0.5s",
                  },
                }}
                onClick={handleCheckUser}
              >
                ADD TO CART
              </Button>
            </Box>
          </>
        );
      })}
    </Box>
  );
}

export default ProductImage;
