import { Box, Container, Typography } from "@mui/material";
import React from "react";
import Layout from "../Layout/Layout";
import vBack from "../../assets/images/vBack.jpg";

function TermAndCondition({ active, setActive }) {
  const style = {
    mainBox: {
      background: `url(${vBack}) no-repeat`,
      backgroundSize: "cover",
      position: "absolute",
      top: "0px",
      width: "100%",
    },
    container: {
      maxWidth: { lg: "1400px", md: "1350px" },
      paddingBottom: "3rem",
    },
    contact: { marginY: "3rem", width: "100%" },
    heading: {
      textAlign: "flex-start",
      color: "white",
      fontWeight: 800,
      marginBottom: "2rem",
      fontStyle: "italic",
      letterSpacing: "5px",
    },
    para: {
      textAlign: "flex-start",
      color: "white",
      fontWeight: 600,
      marginBottom: "2rem",
      fontStyle: "italic",
      letterSpacing: "2px",
      fontSize: "1.2rem",
      marginLeft: "30px",
    },
  };
  return (
    <>
      <Box sx={style.mainBox}>
        <Layout active={active} setActive={setActive}>
          <Container sx={style.container}>
            <Box sx={style.contact}>
              <Typography variant="h4" gutterBottom sx={style.heading}>
                Terms and Conditions
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                Welcome to Biz Pro Quo! These Terms and Conditions outline the
                rules and regulations for the use of our website. By accessing
                this website and purchasing our videos and music, you accept and
                agree to abide by these Terms and Conditions. If you do not
                agree to these terms, please refrain from using our website.
              </Typography>

              <Typography variant="h5" gutterBottom sx={style.heading}>
                1. Definitions
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                - "Website," "we," "us," or "our" refers to Biz Pro Quo.
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                - "User," "you," or "your" refers to any individual or entity
                accessing our website.
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                - "Content" refers to videos, music, text, images, and any other
                materials available on our website.
              </Typography>

              <Typography variant="h5" gutterBottom sx={style.heading}>
                2. Use of the Website
              </Typography>

              <Typography variant="body1" paragraph sx={style.para}>
                1. You must be at least 18 years old or have parental consent to
                use this website.
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                2. You are responsible for maintaining the confidentiality of
                your account and password.
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                3. You may not use our website for any illegal or unauthorized
                purpose.
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                4. You agree not to reproduce, duplicate, copy, sell, resell, or
                exploit any portion of our website without express written
                permission.
              </Typography>

              <Typography variant="h5" gutterBottom sx={style.heading}>
                3. Purchases and Payments
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                1. Our website offers videos and music for purchase.
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                2. By purchasing content, you agree to pay the listed price.
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                3.Payments are processed securely through our payment gateway
                partners.
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                4. All purchases are final. No refunds will be issued, except in
                cases of technical errors on our end.
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                5. Prices are subject to change without notice.
              </Typography>

              <Typography variant="h5" gutterBottom sx={style.heading}>
                4. Intellectual Property
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                1. All content available on the website, including videos and
                music, is the intellectual property of Biz Pro Quo.
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                2. You may not reproduce, distribute, modify, create derivative
                works, or publicly display any of our content without prior
                written consent.
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                3. You may use purchased content for personal, non-commercial
                use only.
              </Typography>

              <Typography variant="h5" gutterBottom sx={style.heading}>
                5. User Content
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                1. You may have the option to submit reviews, comments, or other
                user-generated content.
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                2. By submitting such content, you grant us a non-exclusive,
                royalty-free, perpetual, and worldwide license to use, modify,
                reproduce, and distribute it.
              </Typography>

              <Typography variant="h5" gutterBottom sx={style.heading}>
                6. Privacy Policy
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                Your use of the website is also governed by our Privacy Policy,
                which can be found on our website.
              </Typography>

              <Typography variant="h5" gutterBottom sx={style.heading}>
                7. Termination
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                We reserve the right to terminate or suspend your account and
                access to the website at our sole discretion, without prior
                notice or liability.
              </Typography>

              <Typography variant="h5" gutterBottom sx={style.heading}>
                8. Limitation of Liability
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                1. We strive to provide accurate and reliable content, but we do
                not guarantee the accuracy, completeness, or usefulness of any
                information on the website.
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                2. We are not liable for any direct, indirect, incidental,
                special, or consequential damages arising from the use or
                inability to use our website.
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                2. We are not liable for any direct, indirect, incidental,
                special, or consequential damages arising from the use or
                inability to use our website.
              </Typography>

              <Typography variant="h5" gutterBottom sx={style.heading}>
                9. Changes to Terms and Conditions
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                We reserve the right to modify or replace these Terms and
                Conditions at any time. Changes will be effective upon posting
                on the website. Your continued use of the website after any such
                changes constitutes acceptance of the new Terms and Conditions.
              </Typography>

              <Typography variant="h5" gutterBottom sx={style.heading}>
                10. Governing Law
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                These Terms and Conditions are governed by and construed in
                accordance with the laws of United State, and you agree to
                submit to the exclusive jurisdiction of the courts in Your City
                in case of any disputes.
              </Typography>

              <Typography variant="body1" paragraph sx={style.para}>
                By using our website and purchasing our videos and music, you
                acknowledge that you have read, understood, and agreed to these
                Terms and Conditions. If you have any questions or concerns,
                please contact us at info@bizproquo.com.
              </Typography>

              <Typography variant="caption" display="block">
                Last updated: 30-August-2023
              </Typography>
            </Box>
          </Container>
        </Layout>
      </Box>
    </>
  );
}
export default TermAndCondition;
