import { Box, Button, Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import background from "../../assets/images/vBack.jpg";
import Layout from "../Layout/Layout";
import PriceCard from "./PriceCard";
import { getSubs, getSubsData } from "../../services/subscription";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Popup from "./popus";
function Pricing({ active, setActive }) {
  const style = {
    container: {
      maxWidth: { lg: "1450px", md: "1400px" },
      paddingTop: "3rem",
    },
    mainHeading: {
      textAlign: "center",
      fontWeight: 900,
      color: "white",
    },
    headingBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    main: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "20px",
      alignItems: "center",
      justifyContent: "space-around",
    },
    innbackground: {
      position: "absolute",
      top: "0px",
      width: "100%",
      backgroundImage: `url(${background})`,
      height: "100vh",
    },
  };
  const [data2, setData2] = useState({});
  const [toggle, setToggle] = useState(false);
  const [data, setData] = useState({});
  const handleUpdate = () => {
    getSubs(uid, data).then((data) => {
      console.log(data);
      toast.dark("Subscription Changed Successfully");
      getSubsData(uid).then((data) => {
        setData2(data);
        console.log(data, "data,upDated");
      });
    });
  };
  const priceData = [
    { name: "Mini", price: "10", id: 1 },
    { name: "Individual", price: "20", id: 2 },
    { name: "Family", price: "30", id: 3 },
  ];
  const user = JSON.parse(localStorage.getItem("user"));
  const uid = user.uid;
  const handleSubscribe = (data) => {
    setData(data);
    fetch("https://localhost:5000/api/subscription-checkout-session", {
      method: "POST",
      body: JSON.stringify({ customerId: uid, plan: data.name }),
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((session) => {
        window.location = session.url;
      })
      .catch((err) => {
        return err;
      });
    // console.log(data);
    // if (!data2.subscription) {
    //   console.log(data2.subscription, "dataSubscription");
    //   // getSubs(uid, data).then((data) => {
    //   //   console.log(data.status);
    //   //   toast.success("Subscription Plan Successfull");
    //   //   getSubsData(uid).then((data) => {
    //   //     setData2(data);
    //   //     console.log(data, "UpdatedData");
    //   //   });
    //   // });
    // } else {
    //   const flteredData = data2.subscription.id === data.id;
    //   if (flteredData === true) {
    //     toast.error("This Plan You Already Subscribed");
    //   } else {
    //     setOpen(true);
    //   }
    // }
  };

  useEffect(() => {
    getSubsData(uid).then((data) => {
      setData2(data);
      console.log(data, "data");
    });
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleClose = (value) => {
    setOpen(false);
  };
  return (
    <>
      <Box sx={style.innbackground}>
        <Layout active={active} setActive={setActive}>
          <Popup click={handleUpdate} open={open} onClose={handleClose} />

          <Box sx={style.box}>
            <Container sx={style.container}>
              <Box sx={style.main}>
                {priceData.map(({ name, price, id }, index) => {
                  return (
                    <>
                      <PriceCard
                        id={id}
                        data={name}
                        price={price}
                        click={handleSubscribe}
                      />
                    </>
                  );
                })}
              </Box>
            </Container>
          </Box>
        </Layout>
        <ToastContainer />
      </Box>
    </>
  );
}

export default Pricing;
