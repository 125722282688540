import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import TaskAltIcon from "@mui/icons-material/TaskAlt";

function PriceCard({ data, price, click, id }) {
  const [isHover, setIsHover] = useState(false);
  const style = {
    mainCardBox: {
      width: "450px",
      height: "500px",
      paddingX: isHover ? "0px" : "1rem",
      borderRadius: "20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "white",
      textAlign: "center",

      transition: "all ease-in 0.5s",
      paddingY: "1rem",
      ":hover": {
        transform: "scaleY(1.03)",
        backgroundColor: "#FCA00C",
        boxShadow: 12,
      },
    },
    price: {
      fontSize: "2rem",
      fontWeight: 700,
      marginTop: "12px",
      paddingX: "1rem",
    },
    coloredBox: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingY: "10px",
      color: "white",

      fontSize: "3rem",
      fontWeight: 700,
      letterspacing: "1px",
      marginTop: "14px",
    },
    loremBox: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "40px",
      flexDirection: "column",
      paddingX: "1rem",
    },
    iconWithTExt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      gap: "18px",
      width: "300px",

      paddingBottom: "7px",
    },
    button: {
      color: "white",
      borderRadius: "20px",
      paddingY: "9px",
      paddingX: "35px",
      marginTop: "14px",
      backgroundColor: "#EC5D3B",
      fontSize: "1.2rem",
      fontWeight: 700,
      ":hover": {
        backgroundColor: "#EC5D3B",
        color: "white",
      },
    },
  };
  return (
    <Box
      sx={style.mainCardBox}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <Typography sx={style.price}>
        ${price}
        <small style={{ fontWeight: 600, fontSize: "1rem" }}>/month</small>
      </Typography>
      <Box
        sx={style.coloredBox}
        style={{
          background: isHover ? "#EC5D3B" : "#FCA00C",
          transition: "all ease-in-out 0.5s",
        }}
      >
        {data}
      </Box>
      <Box sx={style.loremBox}>
        {[0, 0, 0, 0].map((datas, index) => {
          return (
            <>
              <Box sx={style.iconWithTExt}>
                <TaskAltIcon />
                <Typography sx={{ opacity: 0.8, fontSize: "1.1rem" }}>
                  Lorem ipsum dolor sit amet.
                </Typography>
              </Box>
              <hr
                style={{
                  color: "grey",
                  width: "250px",
                  marginBottom: "10px",
                }}
              />
            </>
          );
        })}
      </Box>
      <Button
        sx={style.button}
        onClick={() => {
          click({ data, price, id });
        }}
      >
        GET STARTED
      </Button>
    </Box>
  );
}

export default PriceCard;
