import { Box, Container, Grid, Button } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";

import vBack from "../../assets/images/vBack.jpg";
import v1 from "../../assets/images/b1.jpg";

import Layout from "../Layout/Layout";
import video from "../../assets/videos/vid.mp4";
import video1 from "../../assets/videos/video.mp4";
import video2 from "../../assets/videos/Profile.mp4";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  addVideoIdInUser,
  checkVideoExsistOrNot,
  getVideo,
  AddCountInVideo,
} from "../../services/Video";

function Video({ active, setActive }) {
  const naviagte = useNavigate();
  const [vid, setVid] = useState();
  const style = {
    main: {
      position: "relative",

      "::before": {
        position: "absolute",
        zIndex: "-1",
        width: "100%",
        content: "' '",
        backgroundImage: `url(${vBack})`,
        backgroundSize: "cover",
        top: "-20px",
      },
    },
    Container: {
      maxWidth: { lg: "1450px" },
      paddingTop: "4rem",
    },
    imgBox: {
      width: "100%",

      borderRadius: "1rem",
    },
    puschaseAndDownload: {
      display: "flex",
      flexDirection: "row",
      gap: "1rem",
      marginY: "1rem",
    },
    btn: {
      color: "white !important",
      borderRadius: "20px",
      padding: "1rem",
    },
  };

  const [selectedVideo, setSelectedVideo] = useState(video2);
  const savedVideo = localStorage.getItem("selected video");
  const userState = useSelector((state) => state.user.user);
  const videoRef = useRef();
  let initialVideo;

  console.log(selectedVideo, "selectedVideo");

  const [isPurcahse, setIsPurchase] = useState(false);

  const handleVideoChange = (data) => {
    console.log(data);
    AddCountInVideo(data).then((data) => {});
    JSON.stringify(localStorage.setItem("selected video", data));

    setSelectedVideo(data);
    window.location.reload();
  };
  const handlePuschase = (id) => {
    addVideoIdInUser(id).then((data) => {});
  };
  useEffect(() => {
    if (savedVideo !== null || savedVideo !== undefined) {
      try {
        setSelectedVideo(savedVideo);
      } catch (error) {
        console.error("Error parsing savedVideo:", error);
        initialVideo = video;
        setSelectedVideo(initialVideo);
      }
    } else {
      initialVideo = video;
      setSelectedVideo(initialVideo);
    }
    getVideo().then((data) => {
      setVid(data);
    });
    checkVideoExsistOrNot(selectedVideo).then((data) => {
      if (data) {
        setIsPurchase(true);
      }
    });
  }, []);
  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.controlsList = "nodownload";
    }
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          position: "absolute",
          background: `url(${vBack}) no-repeat`,
          backgroundSize: "cover",
        }}
      >
        <Layout active={active} setActive={setActive}>
          <Container sx={style.Container}>
            <Grid container rowSpacing={3} columnSpacing={3}>
              <Grid item lg={6}>
                {selectedVideo !== null && selectedVideo !== undefined ? (
                  <Box sx={style.imgBox}>
                    <video
                      width="100%"
                      height="100%"
                      controls={true}
                      ref={videoRef}
                      autoPlay={false}
                      style={{ borderRadius: "1rem" }}
                    >
                      <source src={`${selectedVideo}`} type="video/mp4" />
                    </video>
                  </Box>
                ) : (
                  <Box sx={style.imgBox}>
                    <video
                      width="100%"
                      height="100%"
                      ref={videoRef}
                      controls={true}
                      autoPlay={false}
                      muted
                      style={{ borderRadius: "1rem" }}
                    >
                      <source src={selectedVideo} type="video/mp4" />
                    </video>
                    {/* <h1 style={{ color: "white" }}>PlayIng Not selected</h1> */}
                  </Box>
                )}
                {/* <Box sx={style.puschaseAndDownload}>
                  {isPurcahse && (
                    <Button
                      sx={
                        (style.btn,
                        {
                          backgroundColor: "#FDA10B",
                          ":hover": { backgroundColor: "#FDA10B" },
                        })
                      }
                      variant="contained"
                    >
                      DOWNLOAD
                    </Button>
                  )}
                  {!isPurcahse && (
                    <Button
                      sx={
                        (style.btn,
                        {
                          backgroundColor: "#8B1157",
                          ":hover": { backgroundColor: "#8B1157" },
                        })
                      }
                      variant="contained"
                      onClick={() => {
                        if (
                          selectedVideo !== undefined ||
                          selectedVideo !== null
                        ) {
                          if (userState !== null) {
                            handlePuschase(selectedVideo);
                          } else {
                            naviagte("/login");
                          }
                        } else {
                          alert("please select video");
                        }
                      }}
                    >
                      PURCHASE
                    </Button>
                  )}
                </Box> */}
              </Grid>
              <Grid
                item
                lg={6}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  position: "relative",
                }}
              >
                <Box sx={style.imageSlider}>
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={3}
                    sx={{
                      height: "500px",
                      overflowY: "scroll",

                      " &::-webkit-scrollbar": {
                        width: "40px",

                        marginX: "0.5rem",
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "transparent",
                        marginX: "0.5rem",
                        borderRadius: "50%",
                      },
                      "&::-webkit-scrollbar-thumb ": {
                        backgroundColor: "#b47512",
                        borderRadius: "550px",
                        height: "2px !important",
                        border: "7px solid #6F490A",
                        margin: "0.2rem",
                      },
                    }}
                  >
                    {vid &&
                      vid.length > 0 &&
                      vid?.map((item, index) => {
                        return (
                          <Grid item lg={6} key={index}>
                            <Box
                              sx={style.imgBox}
                              onClick={() => handleVideoChange(item)}
                            >
                              <video
                                width="100%"
                                height="100%"
                                ref={videoRef}
                                controls={true}
                                autoPlay={false}
                                poster={v1}
                                style={{ borderRadius: "1rem" }}
                              >
                                <source src={item.Video} type="video/mp4" />
                              </video>
                            </Box>
                          </Grid>
                        );
                      })}
                    <Box
                      sx={{
                        height: "100%",
                        width: "3px",
                        borderRadius: "50%",
                        position: "absolute",
                        zIndex: "-1",
                        right: 15,
                        borderLeft: "4px solid grey",
                        opacity: 0.5,
                      }}
                    ></Box>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Layout>
      </Box>
    </>
  );
}

export default Video;
