import { Box, Container, Grid, Typography, Button } from "@mui/material";
import vBack from "../../assets/images/vBack.jpg";
import Layout from "../Layout/Layout";
import d1 from "../../assets/images/d1.jpg";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCart } from "../../slices/cartSlice";
import { useDispatch } from "react-redux";
import { increaseQuantity, decreaseQuantity } from "../../slices/cartSlice";

function AddToCart({ active, setActive }) {
  const style = {
    container: {
      maxWidth: { lg: "1450px", md: "1200px" },
    },
    mainBox: {
      position: "relative",
    },
    customeGrid: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginY: "2rem",
    },
    text: {
      color: "grey",
      fontSize: { md: "1.3rem", xs: "1rem" },
      color: "white",
      marginLeft: "0.5rem",
    },
    pumpStyle: {
      fontSize: "1.1rem",
      fontWeight: 600,
      marginY: "1rem",
    },
    counter: {
      display: "flex",
      flexDirection: "row",
      gap: "0.7rem",
      marginY: "1rem",
      alignItems: "center",
    },
    decrement: {
      borderWidth: 2,
      borderColor: " black",
      borderRadius: "1rem",
      color: "black",
      fontSize: "1rem",
      fontWeight: "bold",
      borderStyle: "solid",
    },
    valueCounter: {
      paddingY: "0.5rem",
      paddingX: "1.5rem",
      borderColor: "#EA533A",
      borderRadius: "1rem",
      color: "black",
      fontSize: "1rem",
      fontWeight: 700,
      borderWidth: 1.5,
      borderStyle: "solid",
      borderWidth: 2,
      backgroundColor: "#EA533A",
    },
    itemBox: {
      marginY: "1rem",
      backgroundColor: "#FDA10B",
      color: "white",
      paddingX: "2rem",
      paddingY: "1rem",
      borderRadius: "1rem",
    },
    itemBox1: {
      marginY: "1rem",
      backgroundColor: "#EA533A",
      color: "white",
      paddingX: "2rem",
      paddingY: "1rem",
      borderRadius: "1rem",
    },
    item: {
      color: "white",
      fontSize: "1.5rem",
      letterSpacing: "0.1rem",
      fontWeight: 700,
    },
    selectedItems: {
      padding: "1rem",
      borderRadius: "1rem",
    },
    selfAlign: {},
    product: {
      fontSize: "1rem",
      fontWeight: 600,
    },
    orderItems: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "1rem",
    },
    SuborderItems: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingX: "1rem",
      paddingY: "0.3rem",
    },
    totalItems: {
      fontSize: "1.2rem",
      fontWeight: "600",
    },
    totalItems1: {
      fontSize: "1.2rem",
      fontWeight: "500",
      color: "#4e4940",
      cursor: "pointer",
    },
    GrandTotal: {
      fontSize: "1.4rem",
      fontWeight: "800",
    },
    button: {
      width: "100%",
      marginY: "0.5rem",
      backgroundColor: "#FDA10B",
      borderRadius: "2rem",
      paddingY: "0.4rem",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "1.5rem",
      fontWeight: 600,
      color: "white",
      ":hover": {
        width: "100%",
        marginY: "0.5rem",
        backgroundColor: "#FDA10B",
        borderRadius: "2rem",
        paddingY: "0.4rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "1.5rem",
        fontWeight: 600,
        color: "white",
      },
    },
    buttonNew: {
      width: "100%",
      marginY: "0.5rem",
      backgroundColor: "#EA533A",
      borderRadius: "2rem",
      paddingY: "0.4rem",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "1.5rem",
      fontWeight: 600,
      color: "white",
      ":hover": {
        width: "100%",
        marginY: "0.5rem",
        backgroundColor: "#EA533A",
        borderRadius: "2rem",
        paddingY: "0.4rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "1.5rem",
        fontWeight: 600,
        color: "white",
      },
    },
    imgBox: {
      width: "90%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    Headings: {
      fontSize: "3rem",
      fontWeight: 800,
      color: "white",
    },
  };
  const inputFeild = {
    borderRadius: "1.3rem",
    width: "70%",
    height: "40px",
    paddingLeft: "2rem",
    backgroundColor: "white",
    color: "grey",
    backgroundColor: "transparent",
  };
  const inputFeildContainer = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.4rem",
  };
  const dispatch = useDispatch();
  const cartData = useSelector(selectCart);
  useEffect(() => {
    console.log(cartData, "cartData");
  }, [cartData]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          position: "absolute",
          background: `url(${vBack}) no-repeat`,
          backgroundSize: "cover",
        }}
      >
        <Layout active={active} setActive={setActive}>
          <Container sx={style.container}>
            <Box sx={style.mainBox}>
              <Box sx={style.customeGrid}>
                <Box sx={style.leftSide}>
                  <Typography sx={style.Headings}>YOUR CART</Typography>
                  <Typography sx={style.text}>
                    Congratulations! You qualify for free shipping
                  </Typography>
                </Box>
                <Box sx={style.rightSide}></Box>
              </Box>
              <Grid container columnSpacing={3}>
                <Grid item md={8} xs={12}>
                  <Box sx={style.itemBox}>
                    <Typography sx={style.item}>SIGN UP</Typography>
                  </Box>
                  <Box sx={style.selectedItems}>
                    {cartData?.map((data, index) => {
                      return (
                        <>
                          <Grid
                            container
                            key={index}
                            backgroundColor="white"
                            borderRadius="1rem"
                            padding="1rem"
                            marginBottom="1rem"
                          >
                            <Grid item lg={5}>
                              <Box sx={style.imgBox}>
                                <img
                                  alt="product"
                                  src={data?.details[0]?.image}
                                  style={{
                                    borderRadius: "1rem",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                />
                              </Box>
                            </Grid>
                            <Grid item lg={6}>
                              <Typography sx={style.pumpStyle}>
                                {data?.details[0]?.model}
                              </Typography>
                              <Typography sx={style.product}>
                                {data?.details[0]?.title}
                              </Typography>
                              <Typography sx={{ marginY: "0.5rem" }}>
                                {data?.details[0]?.brand}
                              </Typography>
                              {/* <Typography sx={style.pumpStyle}>
                                COLOR
                              </Typography> */}
                              <Typography>{data?.details[0]?.type}</Typography>
                              <Typography sx={style.pumpStyle}>
                                Quantity :
                              </Typography>
                              <Box sx={style.counter}>
                                <Button
                                  sx={style.decrement}
                                  onClick={() =>
                                    dispatch(
                                      decreaseQuantity({
                                        title: data?.details[0]?.title,
                                      })
                                    )
                                  }
                                >
                                  -
                                </Button>
                                <Typography sx={style.valueCounter}>
                                  {data.quantity}
                                </Typography>
                                <Button
                                  sx={style.decrement}
                                  onClick={() =>
                                    dispatch(
                                      increaseQuantity({
                                        title: data?.details[0]?.title,
                                      })
                                    )
                                  }
                                >
                                  +
                                </Button>
                              </Box>
                            </Grid>
                            <Grid item lg={1} sx={style.selfAlign}>
                              <Typography sx={style.pumpStyle}>
                                PRICE
                              </Typography>
                              <Typography sx={style.pumpStyle}>$420</Typography>
                            </Grid>
                          </Grid>
                        </>
                      );
                    })}
                  </Box>
                </Grid>

                <Grid item md={4} xs={12}>
                  <Box sx={style.itemBox1}>
                    <Typography sx={style.item}>ORDER SUMMARY</Typography>
                  </Box>
                  <Box sx={style.selectedItems} backgroundColor="white">
                    <Box sx={style.orderItems}>
                      <Typography sx={style.totalItems}>Sub Total :</Typography>
                      <Typography sx={style.totalItems}>$ 0.00</Typography>
                    </Box>
                    <hr />
                    <Box sx={style.orderItems}>
                      <Typography sx={style.totalItems}>Shipping :</Typography>
                      <Typography component="a" sx={style.totalItems1}>
                        Add Info
                      </Typography>
                    </Box>
                    <hr />
                    <Box sx={style.orderItems}>
                      <Typography sx={style.totalItems}>Tax :</Typography>
                      <Typography sx={style.totalItems1}>$4.20</Typography>
                    </Box>
                    <hr />
                    <Box sx={style.orderItems}>
                      <Typography sx={style.totalItems}>
                        Coupon Code :
                      </Typography>
                      <Typography component="a" sx={style.totalItems1}>
                        Add Coupon
                      </Typography>
                    </Box>

                    <Box sx={style.orderItems}>
                      <Typography sx={style.GrandTotal}>
                        Grand Total :
                      </Typography>
                      <Typography sx={style.GrandTotal}>$ 64.20</Typography>
                    </Box>

                    <Button sx={style.button}>CHECKOUT</Button>
                    <Button sx={style.buttonNew}>CHECKOUT</Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Layout>
      </Box>
    </>
  );
}

export default AddToCart;
