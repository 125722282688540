import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import Layout from "../Layout/Layout";
import vBack from "../../assets/images/vBack.jpg";
import { useNavigate } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { collection, getDocs } from "@firebase/firestore";
import { db } from "../../firebaseConfig";
import { AddCountInBlog } from "../../services/Blog";
import { motion } from "framer-motion";

function Blogs({ active, setActive }) {
  const navigate = useNavigate();
  const [blogData, setBlogData] = useState([]);
  const handleReadMore = (data, count) => {
    let temp = data?.count + count;

    const updated = {
      ...data,
      count: temp,
    };
    AddCountInBlog(updated).then((data) => {});
    localStorage.setItem("detail blog", JSON.stringify(data));
    navigate("/blog-details");
  };
  useEffect(() => {
    const blog = async () => {
      const ref = collection(db, "Blog");
      const querySnapshot = await getDocs(ref);
      const blogData = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        const dateInSeconds = data.date; // Assuming data.date is in seconds
        const timezoneOffsetInMilliseconds =
          new Date().getTimezoneOffset() * 60 * 1000;
        const dateInMillis =
          dateInSeconds * 1000 - timezoneOffsetInMilliseconds; // Adjust for timezone offset
        const date = new Date(dateInMillis);

        const monthName = new Intl.DateTimeFormat("en-US", {
          month: "long",
        }).format(date);
        const dateNumber = date.getDate();

        // Convert HTML description to plain text
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(data.description, "text/html");
        const textDescription = htmlDoc.body.textContent || "";

        // Truncate description to 30 characters and add ellipsis if too long
        const truncatedDescription =
          textDescription.length > 50
            ? textDescription.substring(0, 40) + "..."
            : textDescription;

        return {
          ...data,
          truncatedDescription,
          monthName,
          dateNumber,
        };
      });

      setBlogData(blogData);
    };

    blog();
  }, []);
  return (
    <>
      <Box sx={style.main}>
        <Layout active={active} setActive={setActive}>
          <Container sx={style.Container}>
            <Typography sx={style.Headings}>BLOGS</Typography>
            <Grid container marginY="2rem">
              {blogData?.map((img, index) => {
                return (
                  <Grid item lg={6} xs={12} key={index}>
                    <Box sx={style.blogcard}>
                      <Grid container sx={{ height: "100%" }}>
                        <Grid item md={6} xs={12} order={img.order ? 2 : 1}>
                          <Box sx={style.imgSide}>
                            <img
                              src={img?.image}
                              alt="blogs image"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </Box>
                        </Grid>
                        <Grid item md={6} xs={12} order={img.order ? 1 : 2}>
                          <Box sx={style.gradientBox}>
                            <Box sx={style.gradientInnerBox}>
                              <Box sx={style.cardTop}>
                                <Box sx={style.gradientBox}>
                                  <Typography sx={style.numberfont}>
                                    {img.dateNumber}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",

                                    flexDirection: "column",
                                  }}
                                >
                                  <motion.div
                                    initial={{ opacity: 0, y: 40 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{
                                      ease: [0.17, 0.67, 0.83, 0.67],
                                      delay: index * 0.2,
                                      type: "spring",
                                      stiffness: 50,
                                    }}
                                  >
                                    <Typography sx={style.numberfont1}>
                                      {img.monthName}
                                    </Typography>
                                  </motion.div>
                                  <motion.div
                                    initial={{ opacity: 0, y: 40 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{
                                      ease: [0.17, 0.67, 0.83, 0.67],
                                      delay: index * 0.2,
                                      type: "spring",
                                      stiffness: 50,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",

                                        flexDirection: "row",
                                        gap: "10px",
                                      }}
                                    >
                                      <AccessTimeIcon sx={{ color: "white" }} />
                                      <Typography sx={style.Time}>
                                        {img.time}
                                      </Typography>
                                    </Box>
                                  </motion.div>
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  width: "100%",
                                  marginY: "0.5rem",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  sx={{ fontWeight: 800, color: "white" }}
                                >
                                  {img.Name}
                                </Typography>
                                <Typography
                                  sx={{
                                    width: "100%",
                                    marginY: "0.5rem",
                                    color: "white",
                                  }}
                                >
                                  {img.truncatedDescription}
                                </Typography>

                                <Button
                                  sx={{ color: "white", fontWeight: 700 }}
                                  onClick={() => handleReadMore(img, 1)}
                                >
                                  READ MORE{" "}
                                  <KeyboardDoubleArrowRightIcon
                                    sx={{ color: "white" }}
                                  />
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        </Layout>
      </Box>
    </>
  );
}

export default Blogs;
const style = {
  main: {
    width: "100%",
    position: "absolute",
    background: `url(${vBack}) no-repeat`,
    backgroundSize: "cover",
    minHeight: "100vh",
  },
  Container: {
    maxWidth: { xl: "1500px" },
  },
  Headings: {
    fontSize: "3rem",
    fontWeight: 800,
    color: "white",
  },
  blogcard: {
    width: "100%",
    height: "100%",
    transition: "all ease-in-out 0.5s",
    transform: { xs: "scale(0.8)", lg: "scale(1)" },
    ":hover": {
      transform: "scale(0.9)",
    },
  },
  imgSide: {
    height: "400px",
  },
  gradientBox: {
    height: "100%",
    background: " #FDC830" /* fallback for old browsers */,
    background:
      "-webkit-linear-gradient(135deg, #F37335, #FDC830)" /* Chrome 10-25, Safari 5.1-6 */,
    background:
      "linear-gradient(135deg, #F37335, #FDC830)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */,
  },
  gradientInnerBox: {
    width: "100%",
    height: "100%",
    padding: "1rem",
  },
  numberfont: {
    color: "white",
    fontWeight: 800,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "2rem",
    padding: "1.5rem",
  },
  numberfont1: {
    color: "white",
    fontWeight: 800,
    display: "flex",
    alignItems: "start",
    justifyContent: "start",
    fontSize: "2.5rem",
  },
  cardTop: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    alignItems: "start",
  },
  Time: {
    color: "white",
  },
};
