import React from "react";
import Header from "../header/Header";
import Footer from "./Footer";
import ProductImage from "./ProductImage";
import RelatedProduct from "./RelatedProduct";
import background from "../../assets/images/pBack.jpg";
import { Box, Container } from "@mui/material";

function ProductDetails({ active, setActive }) {
  const style = {
    container: {
      maxWidth: { lg: "1450px", md: "1400px" },
      paddingTop: "5rem",
      marginBottom: "8rem",
    },
    main: {
      position: "absolute",

      width: "100%",
      backgroundImage: `url(${background})`,

      backgroundSize: "cover",
    },
  };
  return (
    <Box sx={style.main}>
      <Header active={active} setActive={setActive} />
      <Container sx={style.container}>
        <ProductImage />
        <RelatedProduct />
      </Container>
      <Footer />
    </Box>
  );
}

export default ProductDetails;
