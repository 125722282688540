import { createSlice } from "@reduxjs/toolkit";
const productDetail = createSlice({
  name: "productDetail",
  initialState: [],
  reducers: {
    productDetails: (state, action) => {
      state.length = 0;
      const productToAdd = action.payload;
      state.push(productToAdd);
    },
  },
});
export const { productDetails } = productDetail.actions;
export const selectProductDetails = (state) => state.productDetail;
export default productDetail.reducer;
