import { getAuth } from "firebase/auth";
import {
  collection,
  doc,
  updateDoc,
  query,
  where,
  getDocs,
  increment,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
const auth = getAuth();

export const addVideoIdInUser = async (Id) => {
  const addVideo = [];
  addVideo.push(Id);

  if (auth.currentUser.email) {
    const userRef = collection(db, "userSignUp");
    const q = query(userRef, where("email", "==", auth.currentUser.email));
    const querySnapshot = await getDocs(q);
    // console.log(Id, "id");
    querySnapshot.forEach(async (docs) => {
      const docRef = doc(db, "userSignUp", docs.id);

      await updateDoc(docRef, { video: [...docs.data().video, ...addVideo] });
    });
  } else {
    return console.log("user isnt Login or Signup");
  }
};
export const checkVideoExsistOrNot = async (id) => {
  let purchase = false;
  if (auth.currentUser?.email) {
    const userRef = collection(db, "userSignUp");
    const q = query(userRef, where("email", "==", auth.currentUser?.email));
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (docs) => {
      docs.data()?.video?.forEach((data) => {
        if (id === data) {
          purchase = true;
        } else {
          console.log("data not found");
        }
      });
    });
  } else {
    return console.log("user data isnt found");
  }
  return purchase;
};
// const getAllVideoById = async () =>{
//     if (auth.currentUser?.email) {
//         const userRef = collection(db, "userSignUp");
//         const q = query(userRef, where("email", "==", auth.currentUser?.email));
//         const querySnapshot = await getDocs(q);

//         querySnapshot.forEach(async (docs) => {
//           docs.data().video.forEach((data) => {
//             if (id === data) {
//               purchase = true;
//             } else {
//               console.log("data not found");
//             }
//           });
//         });
//       } else {
//         return console.log("user please sign or log In");
//       }
// }
// export const getVideo = async()=>{
//     let Videos = [];
//     const collectionRef = db.collection('Videos'); // Replace 'yourCollection' with your actual collection name

// collectionRef
//   .get()
//   .then((querySnapshot) => {
//     querySnapshot.forEach((doc) => {
//         Videos.push(doc.data());
//     });
//   })
//   .catch((error) => {
//     console.error('Error getting documents: ', error);
//   });
// }
export const getVideo = async () => {
  let Data = [];
  const querySnapshot = await getDocs(collection(db, "Videos"));
  querySnapshot.forEach((doc) => {
    Data.push(doc.data());
  });
  console.log(Data, "video Data");
  return Data;
};
export const AddCountInVideo = async (data) => {
  const Ref = collection(db, "Videos");
  const q = query(Ref, where("Name", "==", data.Name));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(async (docs) => {
    const docRef = doc(db, "Videos", docs.id);

    await updateDoc(docRef, { count: increment(1) });
  });
};
