import React from "react";
import { Typography } from "@mui/material";
import { Box, Container, Grid } from "@mui/material";
import img from "../../assets/images/b1.jpg";
function Videos({ src, title }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      .{" "}
      <Box sx={{ borderRadius: "1rem" }}>
        <video
          controls
          autoPlay={false}
          width="100%"
          style={{ borderRadius: "1rem" }}
          src={src}
          poster={img}
          type="video/mp4"
        ></video>
      </Box>
      <Typography
        sx={{
          fontSize: 35,
          fontWeight: "bold",
          marginY: "0.5rem",
          color: "#404040",
        }}
      >
        {title}
      </Typography>
      <hr />
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <PlayArrowIcon
          sx={{
            width: 60,
            height: 60,
            color: "white",
            backgroundColor: `${props.colour}`,
            borderRadius: "50%",
          }}
        />
        

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "black",
              textAlign: "left",
              fontSize: 18,
              fontWeight: "bold",
            }}
          >
            Beats Title Name
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "#ACB3BA",
                marginLeft: 1,
                fontSize: 14,
                fontWeight: "300",
                marginLeft: 2,
              }}
            >
              5:12 PM
            </Typography>
            <Typography
              sx={{
                color: "#ACB3BA",
                marginLeft: 1,
                fontSize: 14,
                fontWeight: "300",
                marginLeft: 2,
              }}
            >
              belanja di pastar
            </Typography>
          </Box>
        </Box>

        <Typography
          sx={{
            color: "#ACB3BA",
            textAlign: "right",
            fontSize: 20,
            fontWeight: "bold",
          }}
        >
          $56
        </Typography>
      </Box> */}
    </Box>
  );
}

export default Videos;
