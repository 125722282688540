import { db } from "../firebaseConfig";
import {
  collection,
  addDoc,
  getDocs,
  doc,
  updateDoc,
  query,
  where,
  setDoc,
} from "firebase/firestore";

export const userAddDoc = async (data, uid) => {
  await setDoc(doc(db, "userSignUp", uid), data);
};

export const getUserDoc = async (data) => {
  let userData = {};
  const querySnapshot = await getDocs(collection(db, "userSignUp"));
  querySnapshot.forEach((doc) => {
    if (doc.data().email === data) {
      userData = doc.data();
    }
  });
  console.log(userData, "userData");
  return userData;
};
export const updateUserDoc = async (data) => {
  const collectionRef = collection(db, "userSignUp");
  const keyToUpdate = "email"; // Replace with the specific key you want to match
  const valueToMatch = data.email; // Replace with the specific value you want to match

  // Create a query to find documents where the specific key matches the value
  const q = query(collectionRef, where(keyToUpdate, "==", valueToMatch));

  // Get the matching documents
  const querySnapshot = await getDocs(q);

  // Iterate through the documents and update them
  querySnapshot.forEach((item) => {
    const docRef = doc(db, "userSignUp", item.id);

    // Specify the updates you want to make

    // Update the document
    updateDoc(docRef, data)
      .then(() => {
        console.log(`Document  updated successfully.`);
      })
      .catch((error) => {
        console.error(`Error updating document :`, error);
      });
  });
};
