import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";

export const getAlbum = async () => {
  let Data = [];
  const querySnapshot = await getDocs(collection(db, "Album"));
  querySnapshot.forEach((doc) => {
    Data.push(doc.data());
  });
  console.log(Data, "userData");
  return Data;
};
