import p1 from "../../assets/images/p1.jpg";
import p2 from "../../assets/images/p2.jpg";
import p3 from "../../assets/images/p3.jpg";
import p4 from "../../assets/images/p4.jpg";
import p5 from "../../assets/images/p5.jpg";
import p6 from "../../assets/images/p6.jpg";
import p7 from "../../assets/images/p7.jpg";
import p8 from "../../assets/images/p8.jpg";
import p9 from "../../assets/images/p9.jpg";
import p10 from "../../assets/images/p10.jpg";
import p11 from "../../assets/images/p11.jpg";
import p12 from "../../assets/images/p12.jpg";
import { Box, Container, Typography, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import background from "../../assets/images/pBack.jpg";
import Layout from "../Layout/Layout";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { productDetails } from "../../slices/productDetails";
import { getProductsWithPangination } from "../../services/Product";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
function Product({ active, setActive }) {
  const [productData, setProductData] = useState();
  const [productCount, setProductCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const style = {
    main: {
      position: "absolute",

      width: "100%",
      backgroundImage: `url(${background})`,

      backgroundSize: "cover",
    },
    mainBox: {
      paddingTop: "2rem",
    },
    buzzyText: {
      textAlign: "center",
      fontFamily: "Bassy",
      marginTop: "2rem",
      color: "white",
    },
    head: {
      textAlign: "center",
      marginY: "2rem",
      color: "white",
      fontWeight: "700",
      fontStyle: "italic",
    },
    cont: {
      paddingY: "3rem",
    },
    prices: {
      fontWeight: 700,
      fontSize: "1rem",
      textAlign: "center",
      marginY: "1.3rem",
      color: "white",
    },
    productBox: {
      width: "100%",
      height: "100%",
    },
  };
  const mapData = [
    {
      img: p1,
      title: "Music Cap",
      price: "$ 10.00",
      quantity: 1,
    },
    {
      img: p2,
      title: "Shirt",
      price: "$ 20.00",
      quantity: 1,
    },
    {
      img: p3,
      title: "key Chain",
      price: "$ 60.00",
      quantity: 1,
    },
    {
      img: p4,
      title: "Product Name",
      price: "$ 05.00",
      quantity: 1,
    },
    {
      img: p5,
      title: "Product Name",
      price: "$ 80.00",
      quantity: 1,
    },
    {
      img: p6,
      title: "Product Name",
      price: "$ 100.00",
      quantity: 1,
    },
    {
      img: p7,
      title: "Product Name",
      price: "$ 20.00",
      quantity: 1,
    },
    {
      img: p8,
      title: "Product Name",
      price: "$ 35.00",
      quantity: 1,
    },
    {
      img: p9,
      title: "Product Name",
      price: "$ 00.00",
      quantity: 1,
    },
    {
      img: p10,
      title: "Product Name",
      price: "$ 65.00",
      quantity: 1,
    },
    {
      img: p11,
      title: "Product Name",
      price: "$ 56.00",
      quantity: 1,
    },
    {
      img: p12,
      title: "Product Name",
      price: "$ 35.00",
      quantity: 1,
    },
  ];
  const dispatch = useDispatch();
  const handleAddToCart = (item) => {
    dispatch(productDetails(item));
  };
  useEffect(() => {
    setIsLoading(true);
    const offSet = (page - 1) * 12;
    getProductsWithPangination(offSet).then((data) => {
      console.log(data, "coming products");
      setProductData(data?.products);
      setProductCount(data?.count);
      setIsLoading(false);
    });
  }, [page]);
  return (
    <>
      <Box sx={style.main}>
        <Layout active={active} setActive={setActive}>
          <Box sx={style.mainBox}>
            <Container sx={style.cont}>
              <Typography variant="h2" sx={style.buzzyText}>
                Featured
              </Typography>
              <Typography variant="h3" sx={style.head}>
                PRODUCTS
              </Typography>
              {isLoading && (
                <>
                  <Grid
                    container
                    rowSpacing={3}
                    columnSpacing={3}
                    marginY="3rem"
                  >
                    {[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map(() => {
                      return (
                        <Grid item lg={3}>
                          <Box sx={style.productBox}>
                            <Skeleton
                              variant="rounded"
                              sx={{
                                backgroundColor: "rgba(255,255,255,0.3)",
                                height: "200px",
                                width: "100%",
                                borderRadius: "1rem",
                              }}
                            />
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Skeleton
                                variant="rounded"
                                animation="wave"
                                height={30}
                                sx={{
                                  backgroundColor: "rgba(255,255,255,0.3)",
                                  borderRadius: "1rem",
                                  width: "90%",
                                  marginY: "0.3rem",
                                }}
                              />
                              <Skeleton
                                variant="rounded"
                                animation="wave"
                                height={30}
                                sx={{
                                  backgroundColor: "rgba(255,255,255,0.3)",

                                  width: "70%",
                                  borderRadius: "1rem",
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </>
              )}
              {!isLoading && (
                <Grid container rowSpacing={3} columnSpacing={3}>
                  {productData?.map((data, index) => {
                    return (
                      <Grid
                        item
                        lg={3}
                        sx={{ marginBottom: "4rem" }}
                        onClick={() => handleAddToCart(data)}
                      >
                        <Link to={`/product/${data.id}`}>
                          <motion.div>
                            <Box sx={style.productBox}>
                              <Box
                                sx={{
                                  height: "100%",
                                  width: "100%",
                                  transition: "all ease-in-out 0.5s",
                                  ":hover": {
                                    transform: "scale(1.1)",
                                    transition: "all ease 0.5s",
                                  },
                                }}
                              >
                                <img
                                  src={data.image}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "1rem",
                                  }}
                                />
                              </Box>
                              <Typography sx={style.prices}>
                                {data.title}{" "}
                              </Typography>
                              <Typography sx={style.prices}>
                                {" "}
                                {data.type}
                              </Typography>
                            </Box>
                          </motion.div>
                        </Link>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Pagination
                  count={productCount}
                  variant="outlined"
                  color="primary"
                  onChange={handleChange}
                  sx={{
                    "& .MuiPaginationItem-root": {
                      color: "white",
                      border: "2px solid white",
                      // Change the color to your desired color
                    },
                  }}
                />
              </Box>
            </Container>
          </Box>
        </Layout>
      </Box>
    </>
  );
}

export default Product;
