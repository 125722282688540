import { getAuth } from "firebase/auth";
import {
  collection,
  doc,
  updateDoc,
  query,
  where,
  getDocs,
  increment,
} from "firebase/firestore";
import { db } from "../firebaseConfig";

const auth = getAuth();

export const addMusicInUser = async (music) => {
  const addMusic = [];
  addMusic.push(music);

  const userRef = collection(db, "userSignUp");
  const q = query(userRef, where("email", "==", auth.currentUser.email));
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach(async (docs) => {
    const docRef = doc(db, "userSignUp", docs.id);
    // addMusic.concate(docs.data().music || []);
    await updateDoc(docRef, { music: [...docs.data().music, ...addMusic] });
  });
};

export const getMusic = async () => {
  let allMusic;
  const userRef = collection(db, "userSignUp");
  const q = query(userRef, where("email", "==", auth.currentUser.email));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(async (docs) => {
    allMusic = docs.data().music;
  });
  return allMusic;
};

export const getMusics = async () => {
  let Data = [];
  const querySnapshot = await getDocs(collection(db, "Music"));
  querySnapshot.forEach((doc) => {
    Data.push(doc.data());
  });
  return Data;
};

export const AddCountInMusic = async (data) => {
  const Ref = collection(db, "Music");
  const q = query(Ref, where("albumName", "==", data.albumName));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach(async (docs) => {
    const docRef = doc(db, "Music", docs.id);

    await updateDoc(docRef, { count: increment(1) });
  });
};
