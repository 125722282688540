import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";

export const getEvents = async () => {
  let Data = [];
  const querySnapshot = await getDocs(collection(db, "Event"));
  querySnapshot.forEach((doc) => {
    Data.push({ ...doc.data(), id: doc.id });
  });
  console.log(Data, "Events Data");
  return Data;
};
export const getSpecificEvent = async (id) => {
  let temp = {};
  const docRef = doc(db, "Event", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    temp = docSnap.data();

    console.log("Document data:", docSnap.data());
  } else {
    console.log("No such document!");
  }
  return temp;
};
