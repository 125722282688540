import React from "react";
import { Box, Dialog, DialogTitle, DialogActions, Button } from "@mui/material";
import { useState } from "react";
const styles = {
  btn: {
    backgroundColor: "#FCA00C",
    color: "white",
    ":hover": {
      backgroundColor: "#FCA00C",
      color: "white",
    },
  },
};
function Popup({ click, onClose, open }) {
  return (
    <div>
      <Dialog onClose={onClose} open={open}>
        <Box sx={{ backgroundColor: "white" }}>
          <DialogTitle sx={{ color: "#EC5D3B" }}>
            Are You Sure You Want To Change Your Subscription Plan?
          </DialogTitle>
          <DialogActions>
            <Button
              sx={styles.btn}
              variant="contained"
              onClick={() => {
                click();
                onClose();
              }}
            >
              Yes
            </Button>
            <Button sx={styles.btn} variant="contained" onClick={onClose}>
              No
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

export default Popup;
