import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import video from "../../assets/videos/video.mp4";
import { Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import imag from "../../assets/images/b4.jpg";
import Layout from "../Layout/Layout";
import vBack from "../../assets/images/vBack.jpg";
import calm from "../../assets/images/calm.png";
import romantic from "../../assets/images/romantic.png";
import jazz from "../../assets/images/jazz.png";
import beatseletronic from "../../assets/images/beatseletronic.png";
import { LinearProgress } from "@mui/material";
import beatsspring from "../../assets/images/beatsspring.png";
import { validateName, validateEmail } from "../../utils/utils";
import { signUp, logOut, signIn } from "../../services/Auth";
import { useDispatch } from "react-redux";
import { setUser } from "../../slices/userSlice";
import { useSelector } from "react-redux";
import logo from "../../assets/images/logo.png";
import Cards from "./Cards";
import profimage from "../../assets/images/social/istockphoto-1399788030-170667a.webp";
import PlayCards from "./PlayCards";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Videos from "./Videos";
import { userAddDoc, getUserDoc, updateUserDoc } from "../../services/users";
import { sendEmailVerification, getAuth } from "firebase/auth";
import { getMusic } from "../../services/Music";
function Login({ active, setActive }) {
  const dispatch = useDispatch();
  const auth = getAuth();
  const userBiz = useSelector((state) => state.user.user);
  const [isSignUp, setIsSignUp] = useState(false);
  const [action, setAction] = useState("Profile");
  const [musicData, setMusicData] = useState();
  const [userProfileData, setUserProfileData] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const Action = (value) => {
    setAction(value);
  };
  const vidData = [
    {
      src: video,
      title: "vlog",
    },
    {
      src: video,
      title: "vlog",
    },
    {
      src: video,
      title: "vlog",
    },
    {
      src: video,
      title: "vlog",
    },
    {
      src: video,
      title: "vlog",
    },
    {
      src: video,
      title: "vlog",
    },
    {
      src: video,
      title: "vlog",
    },
    {
      src: video,
    },
    {
      src: video,
      title: "vlog",
    },
    {
      src: video,
      title: "vlog",
    },
    {
      src: video,
      title: "vlog",
    },
    {
      src: video,
      title: "vlog",
    },
    {
      src: video,
      title: "vlog",
    },
    {
      src: video,
      title: "vlog",
    },
  ];
  const data = [
    { name: "Music", price: "25.1", value: "40" },
    { name: "Album", price: "65.1", value: "0" },
    { name: "Vidoes", price: "28.1", value: "55" },
    { name: "Stores", price: "42.1", value: "74" },
    { name: "Events", price: "10.0", value: "29" },
  ];
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    address: "",
  });

  const [isError, setIsError] = useState({
    isErrorName: false,
    nameErrorText: "",
    isErrorEmail: false,
    emailErrorText: "",
    passwordError: false,
    passwordErrorText: "",
  });
  const style = {
    main: {
      backgroundColor: "grey",
      width: "100%",
    },
    mainBox: {
      background: `url(${vBack}) no-repeat`,
      backgroundSize: "cover",
      position: "absolute",
      top: "0px",
      width: "100%",
    },
    container: {
      maxWidth: { lg: "1400px", md: "1350px" },
      paddingBottom: "3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    contact: {
      marginY: "1rem",
      width: "100%",
      backgroundColor: "#404040",
      borderRadius: 7,
      padding: 3,
    },
    contact1: {
      marginY: "3rem",
      width: { md: "50%", xs: "100%" },

      backgroundColor: "#EDEBEC",
      borderRadius: 20,
      padding: 5,
    },
    QuickContact: {
      textAlign: "center",
      color: "black",
      fontWeight: 800,
      marginBottom: "2rem",
    },
    fields: {
      display: "flex",
      flexDirection: "column",

      gap: "0.2rem",
    },
    input: {
      marginY: "0.5rem",
      color: "white",
      border: "1px solid white",
      borderRadius: "1rem",
      width: "100%",
      height: "100%",
      backgroundColor: "white",
      ":focusVisible": {
        border: "none",
        outline: "none",
      },
    },

    button: {
      backgroundColor: "#ec5d3b",
      padding: "20px 3px",
      marginY: "1rem",
      border: "none",
      borderRadius: "50px",
      color: "#fff",
      textTransform: "uppercase",
      fontWeight: "600",
      width: "100%",
      ":hover": {
        backgroundColor: "#ec5d3b",
      },
    },
    font1: {
      fontSize: "1.5rem",
      color: "white",
      fontWeight: "600",
      fontFamily: "'Roboto' 'Poppins'",
      textAlign: "Left",
      textDecoration: "none",
      cursor: "pointer",
    },
    grid: {
      backgroundColor: "white",
      borderRadius: "20px",
    },
    error: {
      color: "red",
    },
  };
  const navigate = useNavigate();
  const handleSignUp = () => {
    let errorData = {
      isErrorName: false,
      nameErrorText: "",
      isErrorEmail: false,
      emailErrorText: "",
      passwordError: false,
      passwordErrorText: "",
    };
    if (!userData.name && isSignUp) {
      errorData.isErrorName = true;
      errorData.nameErrorText = "Name Is Required";
    }
    if (!validateName(userData.name) && isSignUp) {
      errorData.isErrorName = true;
      errorData.nameErrorText =
        "Ensure names are at least 3 letters long and contain no special characters.";
    }
    if (!validateEmail(userData.email)) {
      errorData.isErrorEmail = true;
      errorData.emailErrorText = "Please Enter a valid Email.";
    }
    if (userData.password !== userData.confirmPassword && isSignUp) {
      errorData.passwordError = true;
      errorData.passwordErrorText = "Password Must be same.";
    }
    if (
      errorData.isErrorEmail ||
      errorData.isErrorName ||
      errorData.passwordError
    ) {
      setIsError(errorData);
      setShowLoader(false);

      return;
    } else {
      if (isSignUp) {
        setShowLoader(true);
        signUp(userData.email, userData.password, userData.name)
          .then((user) => {
            localStorage.setItem("user", JSON.stringify(user));
            sendEmailVerification(auth.currentUser)
              .then(() => {
                toast.dark("verification Email Sent");
              })
              .catch((err) => {
                console.log(err);
              });
            userAddDoc(
              {
                email: userData.email,
                phone: userData.phone,
                address: userData.address,
                name: userData.name,
                uid: user.uid,
              },
              user.uid
            ).then((data) => {});
            getUserDoc(userData.email)
              .then((data) => {
                setUserProfileData(data);
              })
              .catch((err) => {
                console.log(err);
              });

            toast.dark("Sign Up Successfully");
            dispatch(setUser(user));
            //window.location.reload();
          })
          .catch((err) => toast.dark("Something Wrong !!"))
          .finally(() => {
            setShowLoader(false);
          });
      } else {
        setShowLoader(true);
        signIn(userData.email, userData.password)
          .then((user) => {
            localStorage.setItem("user", JSON.stringify(user));

            getUserDoc(userData.email).then((data) => {
              setUserProfileData(data);
            });
            const storedUserJSON = localStorage.getItem("user");
            if (storedUserJSON) {
              try {
                // Parse the JSON string into a JavaScript object
                const user = JSON.parse(storedUserJSON);
                dispatch(setUser(user));
                toast.dark("Sign In Successfully");
                navigate("/pricing");
              } catch (err) {
                toast.dark("Incorrect Email or Password");
              }
            }
          })
          .catch((err) => toast.dark("Invalid Email or Password"))
          .finally(() => {
            setShowLoader(false);
          });
      }
    }
  };
  const [error, setError] = useState({
    name: "",
    phone: "",
    address: "",
  });

  const handleOnChangeText = (name, value) => {
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleUpdateProfileData = (name, value) => {
    setUserProfileData({
      ...userProfileData,
      [name]: value,
    });
  };

  const updateUserProfile = () => {
    let ErrorText = {
      name: "",
      phone: "",
      address: "",
    };
    let isError = false;

    if (userProfileData.phone.length !== 11) {
      ErrorText.phone = "Enter phone length Must be 11 digits";
      isError = true;
    }
    if (userProfileData.phone.length <= 0) {
      ErrorText.phone = "Phone Is Required";
      isError = true;
    }
    if (userProfileData.name.length <= 0) {
      ErrorText.name = "Name Is Required";
      isError = true;
    }
    if (userProfileData.name.length > 50) {
      ErrorText.name = "Phone Length Should Be Less Then 50 ";
      isError = true;
    }
    if (userProfileData.address.length > 100) {
      ErrorText.address = "Address Length Should Be Less Then 50";
      isError = true;
    }
    if (userProfileData.address.length <= 0) {
      ErrorText.address = "Address Is Required";
      isError = true;
    }
    if (isError) {
      setError(ErrorText);
      return;
    } else {
      updateUserDoc(userProfileData).then((data) => {
        console.log(data);
        setIsEditing(false);
        setError(ErrorText);
      });
    }
  };
  useEffect(() => {
    if (userBiz) {
      getUserDoc(userBiz?.email).then((data) => {
        setUserProfileData(data);
      });
    }
  }, [userBiz]);
  useEffect(() => {
    if (userBiz) {
      getMusic().then((data) => {
        setMusicData(data);
      });
    }
  }, []);

  return (
    <>
      <Box sx={style.mainBox}>
        <Layout active={active} setActive={setActive}>
          <Container sx={style.container}>
            <Box sx={userBiz ? style.contact : style.contact1}>
              {!userBiz ? (
                <>
                  <Typography variant="h3" sx={style.QuickContact}>
                    {isSignUp ? "SIGN UP" : "LOGIN"}
                  </Typography>
                  <Grid container columnSpacing={3}>
                    <Grid item sm={6} xs={12}>
                      <Button
                        variant="contained"
                        sx={[
                          style.button,
                          {
                            borderRadius: 3,
                            backgroundColor: "#FCA10C",
                            ":hover": {
                              backgroundColor: "#FCA10C",
                            },
                          },
                        ]}
                        onClick={() => setIsSignUp(false)}
                      >
                        Log In
                      </Button>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <Button
                        variant="contained"
                        sx={[style.button, { borderRadius: 3 }]}
                        onClick={() => setIsSignUp(true)}
                      >
                        SIGN UP
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={style.error}>
                        {isError && isError.nameErrorText}
                      </Typography>

                      <input
                        className="inputs"
                        style={{
                          width: "100%",

                          marginBottom: "1rem",
                          color: "black",
                          border: "1px solid white",
                          borderRadius: "1rem",
                          paddingTop: "1.5rem",
                          paddingLeft: "1.5rem",
                          fontSize: "1.2rem",
                          paddingBottom: "2rem",
                          "::placeholder": {
                            color: "black",
                            border: "1px solid white",
                            borderRadius: "1rem",
                            paddingTop: "1.5rem",
                            paddingLeft: "1.5rem",
                            fontSize: "1.2rem",
                            paddingBottom: "2rem",
                            "::placeholder": {
                              color: "black",
                            },
                            "::focus-visible": {
                              border: "none",
                              outline: "none",
                            },
                          },
                        }}
                        placeholder="Name"
                        onChange={(e) =>
                          handleOnChangeText("name", e.target.value)
                        }
                      />

                      <Typography sx={style.error}>
                        {isError && isError.emailErrorText}
                      </Typography>
                      <input
                        className="inputs"
                        style={{
                          width: "100%",
                          marginBottom: "1rem",
                          color: "black",
                          border: "1px solid white",
                          borderRadius: "1rem",
                          paddingTop: "1.5rem",
                          paddingLeft: "1.5rem",
                          fontSize: "1.2rem",
                          paddingBottom: "2rem",
                        }}
                        onChange={(e) =>
                          handleOnChangeText("email", e.target.value)
                        }
                        placeholder="Email"
                      />
                    </Grid>
                    <Typography sx={[style.error, { paddingLeft: "1rem" }]}>
                      {isError && isError.passwordErrorText}
                    </Typography>
                    <Grid item xs={12}>
                      <input
                        className="inputs"
                        style={{
                          width: "100%",

                          marginBottom: "1rem",
                          color: "black",
                          border: "1px solid white",
                          borderRadius: "1rem",
                          paddingLeft: "1.5rem",
                          fontSize: "1.2rem",
                          paddingBottom: "2rem",
                          paddingTop: "1.5rem",
                        }}
                        placeholder="Password"
                        onChange={(e) =>
                          handleOnChangeText("password", e.target.value)
                        }
                        type="password"
                      />
                    </Grid>
                    {isSignUp && (
                      <Grid item xs={12}>
                        <input
                          className="inputs"
                          style={{
                            width: "100%",

                            marginBottom: "1rem",
                            color: "black",
                            border: "1px solid white",
                            borderRadius: "1rem",
                            paddingLeft: "1.5rem",
                            fontSize: "1.2rem",
                            paddingBottom: "2rem",
                            paddingTop: "1.5rem",
                          }}
                          placeholder="Confirm Password"
                          onChange={(e) =>
                            handleOnChangeText(
                              "confirmPassword",
                              e.target.value
                            )
                          }
                          type="password"
                        />
                      </Grid>
                    )}
                    {isSignUp && (
                      <Grid item xs={12}>
                        <input
                          className="inputs"
                          style={{
                            width: "97%",
                            marginBottom: "1rem",
                            color: "black",
                            border: "1px solid white",
                            borderRadius: "1rem",
                            paddingLeft: "1.5rem",
                            fontSize: "1.2rem",
                            paddingBottom: "2rem",
                            paddingTop: "1.5rem",
                          }}
                          placeholder="Phone"
                          onChange={(e) =>
                            handleOnChangeText("phone", e.target.value)
                          }
                        />
                      </Grid>
                    )}
                    {isSignUp && (
                      <Grid item xs={12}>
                        <input
                          className="inputs"
                          style={{
                            width: "97%",
                            marginBottom: "1rem",
                            color: "black",
                            border: "1px solid white",
                            borderRadius: "1rem",
                            paddingLeft: "1.5rem",
                            fontSize: "1.2rem",
                            paddingBottom: "2rem",
                            paddingTop: "1.5rem",
                          }}
                          placeholder="Address"
                          onChange={(e) =>
                            handleOnChangeText("address", e.target.value)
                          }
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        onClick={handleSignUp}
                        sx={[
                          style.button,
                          { backgroundColor: !signUp ? "#FCA10C" : "#ec5d3b" },
                        ]}
                      >
                        {isSignUp ? "Sign up" : "Log in"}
                        {showLoader ? (
                          <CircularProgress
                            sx={{
                              color: "white",
                              width: "20px !important",
                              height: "20px !important",
                              marginX: "1rem",
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </Button>
                      <Button
                        sx={{
                          width: "100%",
                          textAlign: "center",
                          color: "black",
                          fontWeight: 600,
                        }}
                      >
                        <Link
                          style={{ textDecoration: "none", color: "inherit" }}
                          to="/forgetPassword"
                        >
                          Forget Password ?
                        </Link>
                      </Button>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid container>
                    <Grid item lg={3} md={3} sx={{}}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "flex-start",
                          paddingX: "2rem",
                          gap: "40px",
                        }}
                      >
                        <Box>
                          <img src={logo} width={200} height={170} alt="logo" />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            gap: "10px",
                            alignItems: "flex-start",
                          }}
                        >
                          <Box>
                            <img
                              src={profimage}
                              style={{ borderRadius: "1rem" }}
                              width={"80%"}
                              height={"80%"}
                              alt=""
                            />
                          </Box>
                          <Box>
                            <Typography
                              sx={{
                                color: "white",
                                fontSize: "1.5rem",
                                fontWeight: "600",
                              }}
                            >
                              {userProfileData.name}
                            </Typography>
                            <Typography
                              sx={{ color: "white", fontSize: "1rem" }}
                            >
                              {userProfileData.email}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            gap: "45px",
                            alignItems: "flex-start",
                          }}
                        >
                          <Link
                            onClick={() => {
                              Action("Profile");
                            }}
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            Profile
                          </Link>
                          <Link
                            onClick={() => {
                              Action("Music");
                            }}
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            Music
                          </Link>
                          <Link
                            onClick={() => {
                              Action("Video");
                            }}
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            Video
                          </Link>
                          <Link
                            onClick={() => {
                              dispatch(setUser(null));
                              localStorage.setItem(
                                "user",
                                JSON.stringify(null)
                              );
                            }}
                            style={{ textDecoration: "none", color: "white" }}
                          >
                            Log Out
                          </Link>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item lg={9} md={9} sx={style.grid}>
                      {/* Music */}
                      {action === "Music" && (
                        <Grid container sx={{ paddingY: "1.2rem" }}>
                          <Grid
                            item
                            lg={8}
                            md={8}
                            sx={{ backgroundColor: "white" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                gap: "30px",
                                alignItems: "flex-start",
                                paddingX: "2rem",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  gap: "15px",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={profimage}
                                  style={{ borderRadius: "15%" }}
                                  width={80}
                                  height={70}
                                  alt=""
                                />
                                <Typography
                                  sx={{
                                    color: "black",
                                    fontWeight: "bolder",
                                    fontSize: "2rem",
                                    fontWeight: "600",
                                  }}
                                >
                                  admin
                                </Typography>
                              </Box>

                              <Typography
                                sx={{
                                  color: "black",
                                  fontSize: "1.4rem",
                                  fontWeight: "700",
                                }}
                              >
                                Music
                              </Typography>

                              <Box
                                sx={{
                                  backgroundColor: "white",
                                  width: "90%",
                                  borderRadius: 3,
                                  gap: "20px",
                                  alignItems: "flex-start",
                                  display: "flex",
                                  padding: 0,
                                  height: "250px",
                                  flexDirection: "column",
                                  overflowY: "scroll",
                                  position: "relative",

                                  zIndex: 8,
                                  " &::-webkit-scrollbar": {
                                    position: "relative",
                                    zIndex: 99,
                                    width: "5px",
                                    backgroundColor: "transparent",
                                    marginX: "0.5rem",
                                  },
                                  "&::-webkit-scrollbar-track": {
                                    position: "relative",
                                    zIndex: 99,
                                    backgroundColor: "transparent",
                                    marginX: "0.5rem",
                                    borderRadius: "10%",
                                  },
                                  "&::-webkit-scrollbar-thumb ": {
                                    position: "relative",
                                    zIndex: 99,
                                    backgroundColor: "orange",
                                    borderRadius: "150px",
                                    height: "2px !important",
                                    border: "2px solid #6F490A",
                                    margin: "0.2rem",
                                  },
                                }}
                              >
                                {musicData &&
                                  musicData.length > 0 &&
                                  musicData?.map((data, index) => {
                                    return (
                                      <>
                                        <Cards
                                          img={beatseletronic}
                                          colour={"	#FF8C00"}
                                          title={data.title}
                                          src={data.src}
                                          key={index}
                                        />
                                      </>
                                    );
                                  })}
                              </Box>
                              <Box>
                                <Box>
                                  <Typography
                                    sx={{
                                      color: "black",
                                      fontSize: "1.4rem",
                                      fontWeight: "700",
                                      fontFamily: "'Roboto' 'Poppins'",
                                    }}
                                  >
                                    Playlist
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    backgroundColor: "white",

                                    borderRadius: 3,
                                    gap: "40px",
                                    display: "flex",
                                    padding: 0,
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                    flexDirection: "row",
                                    overflowX: "scroll",
                                    height: "200px",
                                    width: "530px",
                                    " &::-webkit-scrollbar": {
                                      position: "relative",
                                      zIndex: 99,
                                      width: "1px",
                                      height: "4px",
                                      backgroundColor: "transparent",
                                    },
                                    "&::-webkit-scrollbar-track": {
                                      position: "relative",
                                      zIndex: 99,
                                      backgroundColor: "transparent",
                                      marginX: "0.5rem",
                                      borderRadius: "10%",
                                    },
                                    "&::-webkit-scrollbar-thumb ": {
                                      position: "relative",
                                      zIndex: 99,
                                      backgroundColor: "orange",
                                      borderRadius: "15px",
                                      height: "1px !important",
                                      border: "2px solid #6F490A",
                                      margin: "0.2rem",
                                    },
                                  }}
                                >
                                  {musicData &&
                                    musicData.length > 0 &&
                                    musicData?.map((data, index) => {
                                      return (
                                        <PlayCards
                                          key={index}
                                          src={beatseletronic}
                                          text={data.title}
                                        />
                                      );
                                    })}

                                  {/* <PlayCards src={jazz} text={"Jazz"} />
                                  <PlayCards
                                    src={beatseletronic}
                                    text={"beatseletronic"}
                                  />
                                  <PlayCards src={vBack} text={"vBack"} />
                                  <PlayCards src={calm} text={"calm"} /> */}
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            sx={{ backgroundColor: "	#FFF5EE" }}
                          >
                            <Box
                              sx={{
                                marginTop: "1rem",
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                gap: "1.6rem",
                              }}
                            >
                              <Typography
                                sx={{ color: "black", fontWeight: "bold" }}
                              >
                                Where your money go?
                              </Typography>
                              {data.map((val) => {
                                return (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "0.5rem",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "8rem",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography>{val.name}</Typography>
                                      <Typography sx={{ fontWeight: "bold" }}>
                                        {val.price}
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <LinearProgress
                                        color="warning"
                                        variant="determinate"
                                        value={val.value}
                                      />
                                    </Box>
                                  </Box>
                                );
                              })}
                              <Box
                                sx={{
                                  width: "220px",
                                  height: "220px",
                                }}
                              >
                                <img
                                  src={imag}
                                  alt=""
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "25px",
                                  }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "8rem",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "22px",
                                    color: "black",
                                    fontWeight: "500",
                                  }}
                                >
                                  Beat
                                </Typography>
                                <Button variant="outlined" color="warning">
                                  Buy now
                                </Button>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                      {action === "Profile" && (
                        <Grid container sx={{ paddingY: "1.2rem" }}>
                          <Grid item lg={8} md={8}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "3rem",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: "1rem",
                              }}
                            >
                              <Avatar
                                alt="Remy Sharp"
                                sx={{ width: "150px", height: "150px" }}
                                src={profimage}
                              />
                              <Grid
                                rowSpacing={4}
                                columnSpacing={3}
                                container
                                sx={{
                                  paddingX: "15px",
                                  alignItems: "center",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                }}
                              >
                                <Grid item md={6} lg={6}>
                                  <Box sx={style.fields}>
                                    <Typography
                                      sx={{
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Name
                                    </Typography>
                                    <TextField
                                      value={userProfileData.name}
                                      variant="outlined"
                                      onChange={(e) =>
                                        handleUpdateProfileData(
                                          "name",
                                          e.target.value
                                        )
                                      }
                                      disabled={!isEditing}
                                    />
                                    <Typography sx={style.error}>
                                      {error && error.name ? error.name : ""}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={6} lg={6}>
                                  <Box sx={style.fields}>
                                    <Typography
                                      sx={{
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Address
                                    </Typography>
                                    <TextField
                                      value={userProfileData.address}
                                      onChange={(e) =>
                                        handleUpdateProfileData(
                                          "address",
                                          e.target.value
                                        )
                                      }
                                      disabled={!isEditing}
                                      variant="outlined"
                                    />
                                    <Typography sx={style.error}>
                                      {error && error.address
                                        ? error.address
                                        : ""}
                                    </Typography>
                                  </Box>{" "}
                                </Grid>
                                <Grid item md={6} lg={6}>
                                  <Box sx={style.fields}>
                                    <Typography
                                      sx={{
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Phone
                                    </Typography>
                                    <TextField
                                      value={userProfileData.phone}
                                      disabled={!isEditing}
                                      onChange={(e) =>
                                        handleUpdateProfileData(
                                          "phone",
                                          e.target.value
                                        )
                                      }
                                      variant="outlined"
                                    />
                                    <Typography sx={style.error}>
                                      {error && error.phone ? error.phone : ""}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item md={6} lg={6}>
                                  <Box sx={style.fields}>
                                    <Typography
                                      sx={{
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Email
                                    </Typography>
                                    <TextField
                                      disabled
                                      value={userProfileData.email}
                                      onChange={(e) =>
                                        handleUpdateProfileData(
                                          "email",
                                          e.target.value
                                        )
                                      }
                                      variant="outlined"
                                    />
                                  </Box>
                                </Grid>
                                <Grid item md={12} lg={12}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Button
                                      color="warning"
                                      variant="outlined"
                                      onClick={() =>
                                        isEditing
                                          ? updateUserProfile()
                                          : setIsEditing(true)
                                      }
                                    >
                                      {isEditing ? "Update" : "Edit"}
                                    </Button>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            sx={{ backgroundColor: "	#FFF5EE" }}
                          >
                            <Box
                              sx={{
                                marginTop: "1rem",
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                gap: "1.5rem",
                              }}
                            >
                              <Typography
                                sx={{ color: "black", fontWeight: "bold" }}
                              >
                                Where your money go?
                              </Typography>
                              {data.map((val) => {
                                return (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "0.5rem",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "8rem",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography>{val.name}</Typography>
                                      <Typography sx={{ fontWeight: "bold" }}>
                                        {val.price}
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <LinearProgress
                                        color="warning"
                                        variant="determinate"
                                        value={val.value}
                                      />
                                    </Box>
                                  </Box>
                                );
                              })}
                              <Box
                                sx={{
                                  width: "220px",
                                  height: "220px",
                                }}
                              >
                                <img
                                  src={imag}
                                  alt=""
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "25px",
                                  }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "5rem",
                                  justifyContent: "space-between",
                                  paddingY: "10px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "22px",
                                    color: "black",
                                    fontWeight: "500",
                                  }}
                                >
                                  Beat
                                </Typography>
                                <Button variant="outlined" color="warning">
                                  Buy now
                                </Button>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      )}

                      {action === "Video" && (
                        <Grid container sx={{ paddingY: "1.2rem" }}>
                          <Grid
                            item
                            lg={8}
                            md={8}
                            sx={{
                              display: "flex",
                              gap: "2rem",
                              flexDirection: "column",
                              padding: "1.5rem",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                gap: "15px",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={profimage}
                                style={{ borderRadius: "15%" }}
                                width={80}
                                height={70}
                                alt=""
                              />
                              <Typography
                                sx={{
                                  color: "black",
                                  fontWeight: "bolder",
                                  fontSize: "2rem",
                                  fontWeight: "600",
                                }}
                              >
                                admin
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                sx={{
                                  fontSize: "25px",
                                  color: "black",
                                  fontWeight: "700",
                                }}
                              >
                                Your Videos
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                backgroundColor: "white",
                                width: "100%",
                                borderRadius: 3,

                                alignItems: "flex-start",
                                display: "flex",

                                height: "500px",
                                flexDirection: "column",
                                overflowY: "scroll",

                                " &::-webkit-scrollbar": {
                                  position: "relative",
                                  zIndex: 99,
                                  width: "5px",
                                  backgroundColor: "transparent",
                                  marginX: "0.5rem",
                                },
                                "&::-webkit-scrollbar-track": {
                                  position: "relative",
                                  zIndex: 99,
                                  backgroundColor: "transparent",

                                  borderRadius: "10%",
                                },
                                "&::-webkit-scrollbar-thumb ": {
                                  position: "relative",
                                  zIndex: 99,
                                  backgroundColor: "orange",
                                  borderRadius: "150px",

                                  border: "2px solid #6F490A",
                                },
                              }}
                            >
                              {vidData.map((val) => {
                                return (
                                  <Videos title={val.title} src={val.src} />
                                );
                              })}
                            </Box>
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            sx={{
                              backgroundColor: "#FFF5EE",
                            }}
                          >
                            <Box
                              sx={{
                                marginTop: "1rem",
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                gap: "1.5rem",
                              }}
                            >
                              <Typography
                                sx={{ color: "black", fontWeight: "bold" }}
                              >
                                Where your money go?
                              </Typography>
                              {data.map((val) => {
                                return (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "0.5rem",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "8rem",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography>{val.name}</Typography>
                                      <Typography sx={{ fontWeight: "bold" }}>
                                        {val.price}
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <LinearProgress
                                        color="warning"
                                        variant="determinate"
                                        value={val.value}
                                      />
                                    </Box>
                                  </Box>
                                );
                              })}
                              <Box
                                sx={{
                                  width: "220px",
                                  height: "220px",
                                }}
                              >
                                <img
                                  src={imag}
                                  alt=""
                                  style={{
                                    height: "100%",
                                    width: "100%",
                                    borderRadius: "25px",
                                  }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: "5rem",
                                  justifyContent: "space-between",
                                  paddingY: "10px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "22px",
                                    color: "black",
                                    fontWeight: "500",
                                  }}
                                >
                                  Beat
                                </Typography>
                                <Button variant="outlined" color="warning">
                                  Buy now
                                </Button>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </Container>
        </Layout>
        <ToastContainer />
      </Box>
    </>
  );
}
export default Login;
