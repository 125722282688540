import React from "react";
import { Box, Button } from "@mui/material";

const Newsletter = () => {
  return (
    <>
      <Box sx={styles.SearchIcon}>
        <input
          placeholder="Your email..."
          style={{
            borderRadius: "2rem",
            width: "80%",
            color: "white",

            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "50px",
            border: "0px",
            outline: "none",
            backdropFilter: "blur(5px)",
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            paddingLeft: "2rem",
            marginLeft: "2rem",
            "::focusVisible": {
              outline: "none",
              borderColor: "white",
            },
            ":placeholder": {
              color: "white",
              opacity: 1,
            },
          }}
        />

        <Button variant="contained">Subscribe</Button>
      </Box>
    </>
  );
};

export default Newsletter;

const styles = {
  Mainsearch: {
    border: "none",
    color: "#fff",
    width: "100%",
  },

  SearchIcon: {
    position: "relative",
    zIndex: "1",
    cursor: "pointer",
    border: "none",
    color: "#000",
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    borderRadius: "100%",
    width: "100%",
    gap: "12px",
    button: {
      backgroundColor: "#ec5d3b",
      padding: "12px 45px",

      border: "none",
      borderRadius: "50px",
      color: "#fff",
      textTransform: "uppercase",
      fontWeight: "600",
      ":hover": {
        backgroundColor: "#ec5d3b",
      },
    },
  },
};
