import { Box, Container, Typography } from "@mui/material";
import React from "react";
import Layout from "../Layout/Layout";
import vBack from "../../assets/images/vBack.jpg";

function TermAndCondition({ active, setActive }) {
  const style = {
    mainBox: {
      background: `url(${vBack}) no-repeat`,
      backgroundSize: "cover",
      position: "absolute",
      top: "0px",
      width: "100%",
    },
    container: {
      maxWidth: { lg: "1400px", md: "1350px" },
      paddingBottom: "3rem",
    },
    contact: { marginY: "3rem", width: "100%" },
    heading: {
      textAlign: "flex-start",
      color: "white",
      fontWeight: 800,
      marginBottom: "2rem",
      fontStyle: "italic",
      letterSpacing: "5px",
    },
    para: {
      textAlign: "flex-start",
      color: "white",
      fontWeight: 600,
      marginBottom: "2rem",
      fontStyle: "italic",
      letterSpacing: "2px",
      fontSize: "1.2rem",
      marginLeft: "30px",
    },
  };
  return (
    <>
      <Box sx={style.mainBox}>
        <Layout active={active} setActive={setActive}>
          <Container sx={style.container}>
            <Box sx={style.contact}>
              <Typography variant="h4" gutterBottom sx={style.heading}>
                Privacy Policy
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                Last updated: 30-August-2023
              </Typography>

              <Typography variant="body1" paragraph sx={style.para}>
                Welcome to Biz Pro Quo! ("us", "we", or "our"). We are committed
                to protecting your privacy and ensuring the security of your
                personal information. This Privacy Policy outlines the types of
                data we collect, how we use and safeguard that information, and
                your rights in relation to your personal data.
              </Typography>

              <Typography variant="h5" gutterBottom sx={style.heading}>
                1. Information We Collect
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                We may collect and process the following types of personal data
                when you visit our website, purchase content, or interact with
                our services:
              </Typography>

              <Typography variant="body1" paragraph sx={style.para}>
                1. Contact Information: Name, email address, phone number, and
                mailing address.
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                2. Payment Information: Credit/debit card details and other
                payment-related information.
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                3. Account Information: Username, password, and other account
                details.
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                4. Usage Data: Information about how you use our website,
                including browsing patterns and interactions.
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                5. Communication Data: Information you provide when you contact
                us, such as emails or messages.
              </Typography>

              <Typography variant="h5" gutterBottom sx={style.heading}>
                2. Use of Personal Data
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                We use your personal data for the following purposes:
              </Typography>

              <Typography variant="body1" paragraph sx={style.para}>
                1. To process and fulfill your purchases and orders.
              </Typography>

              <Typography variant="body1" paragraph sx={style.para}>
                2. To provide access to the content you've purchased and
                facilitate streaming.
              </Typography>

              <Typography variant="body1" paragraph sx={style.para}>
                3. To communicate with you regarding your account, purchases, or
                inquiries.
              </Typography>

              <Typography variant="body1" paragraph sx={style.para}>
                4. To improve our website, content, and services based on usage
                patterns.
              </Typography>

              <Typography variant="body1" paragraph sx={style.para}>
                5. To send promotional emails about new content, special offers,
                or other updates (you can opt-out at any time).
              </Typography>

              <Typography variant="body1" paragraph sx={style.para}>
                6. To comply with legal obligations and prevent fraudulent
                activities.
              </Typography>

              <Typography variant="h5" gutterBottom sx={style.heading}>
                3. Data Security
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                We take data security seriously and implement appropriate
                technical and organizational measures to protect your personal
                data from unauthorized access, loss, alteration, or disclosure.
              </Typography>

              <Typography variant="h5" gutterBottom sx={style.heading}>
                4. Data Sharing
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                We do not sell, trade, or rent your personal data to third
                parties for marketing purposes. However, we may share your
                information with the following parties:
              </Typography>

              <Typography variant="body1" paragraph sx={style.para}>
                1. Payment Processors: To process your payments securely.
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                2. Service Providers: To assist us in delivering our services
                (e.g., hosting, analytics).
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                3. Legal Requirements: When required by law or to protect our
                rights and interests.
              </Typography>

              <Typography variant="h5" gutterBottom sx={style.heading}>
                5. Cookies and Tracking Technologies
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                Our website may use cookies and similar tracking technologies to
                enhance your experience and gather information about your usage
                patterns. You can control cookies through your browser settings.
              </Typography>

              <Typography variant="h5" gutterBottom sx={style.heading}>
                6. Your Rights
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                You have certain rights regarding your personal data:
              </Typography>

              <Typography variant="body1" paragraph sx={style.para}>
                1. Access: Request information about the personal data we hold
                about you.
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                2. Correction: Request correction of inaccurate or incomplete
                data.
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                3. Erasure: Request deletion of your data under certain
                circumstances.
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                4. Data Portability: Receive your data in a structured, commonly
                used format.
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                5. Object: Object to processing based on legitimate interests.
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                6. Withdraw Consent: If processing is based on your consent, you
                can withdraw it at any time.
              </Typography>

              <Typography variant="h5" gutterBottom sx={style.heading}>
                7. Changes to Privacy Policy
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                We may update this Privacy Policy from time to time. We will
                notify you of any changes by posting the updated policy on our
                website.
              </Typography>

              <Typography variant="h5" gutterBottom sx={style.heading}>
                8. Contact Us
              </Typography>
              <Typography variant="body1" paragraph sx={style.para}>
                If you have any questions, concerns, or requests related to your
                personal data or this Privacy Policy, please contact us at
                info@bizproquo.com.
              </Typography>

              <Typography variant="body1" paragraph sx={style.para}>
                By using our website and services, you acknowledge that you have
                read, understood, and agreed to this Privacy Policy.
              </Typography>
            </Box>
          </Container>
        </Layout>
      </Box>
    </>
  );
}
export default TermAndCondition;
