import React, { useRef } from "react";
import { Typography, Box } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-creative";
import { EffectCreative, Mousewheel } from "swiper/modules";
import slide01 from "../../assets/images/banner01.jpeg";
import slide02 from "../../assets/images/banner02.jpg";
import slide03 from "../../assets/images/banner03.jpg";
import "./mainSlider.css";
const Mainslider = () => {
  const swiperRef = useRef(null);
  const items = [
    {
      h5: "BizProQuo",

      image: slide01,
    },
    {
      h5: "BizProQuo",

      image: slide02,
    },
    {
      h5: "BizProQuo",

      image: slide03,
    },
  ];
  const handleMousewheel = (event) => {
    if (swiperRef.current) {
      const deltaY = event.deltaY;
      const swiper = swiperRef.current.swiper;

      if (deltaY > 0) {
        swiper.slideNext();
      } else {
        swiper.slidePrev();
      }
    }
  };
  return (
    <Swiper
      height={"100vh"}
      grabCursor={true}
      ref={swiperRef}
      style={{
        position: "absolute",
        width: "99vw",
      }}
      effect={"creative"}
      creativeEffect={{
        prev: {
          shadow: true,
          translate: [0, "-50%", -1],
        },
        next: {
          translate: [0, "100%", 0],
        },
      }}
      modules={[EffectCreative, Mousewheel]}
      className="mySwiper3"
      autoplay={{
        delay: 2000, // Delay between each slide transition
        disableOnInteraction: true, // Allow interactions to prevent auto-play from stopping
      }}
      mousewheel={{ invert: true, eventsTarget: "container" }}
      loop
      speed={1000}
      onWheel={handleMousewheel}
    >
      {items.map((item, i) => {
        return (
          <SwiperSlide key={i}>
            <Box sx={styles.categoriesitem}>
              <img src={item.image} alt="oops" style={{ width: "100%" }} />
              <Box component="div" sx={styles.sliderItem}>
                <Typography variant="h4" className="universalSerif">
                  {item.h5}
                </Typography>
              </Box>
            </Box>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default Mainslider;

const styles = {
  categoriesitem: {
    position: "relative",
  },
  sliderItem: {
    position: "absolute",
    left: 0,
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    transform: "translate(-20rem, 20rem)",
    width: "100%",
    "& h4": {
      color: "#fff",
      fontSize: "90px",
      textShadow: "2px 2px 4px #000",
      fontStyle: "italic",
      fontWeight: "600",
      textShadow: "8px 8px 0px rgba(0, 0, 0, 1)",
      fontFamily: "universal serif",
    },
    "& p": {
      color: "#fff",
      fontSize: "23px",
      textShadow: "2px 2px 4px #000",
    },
  },
};
