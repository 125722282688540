import React from "react";
import {
  Box,
  Grid,
  Typography,
  Container,
  List,
  ListItem,
} from "@mui/material";
import Newsletter from "../newletter/Newsletter.jsx";

import fb from "../../assets/images/social/fb.png";
import insta from "../../assets/images/i.png";
import youtube from "../../assets/images/y.png";
import music from "../../assets/images/social/music.png";
import play from "../../assets/images/social/play.png";
import spotify from "../../assets/images/s.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Box sx={styles.footerSec}>
      <Container sx={{ maxWidth: { lg: "1600px" } }}>
        <Grid
          container
          maxWidth="xl"
          rowSpacing={1}
          pb={2}
          alignItems={"center"}
        >
          <Grid item lg={4} md={6} xs={12}>
            <Typography variant="h2">STAY CONNECTED</Typography>
          </Grid>

          <Grid item sm={9} md={6} lg={8} xs={12}>
            <Newsletter />
          </Grid>
        </Grid>
        <Grid container maxWidth="xl" rowSpacing={1} pb={0}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box sx={styles.innerContanier}>
              <List>
                <ListItem>
                  <Box sx={styles.imgBox}>
                    <img
                      alt="social link"
                      src={insta}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                  <Box sx={styles.imgBox}>
                    <img
                      alt="social link"
                      src={fb}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                  <Box sx={styles.imgBox}>
                    <img
                      alt="social link"
                      src={youtube}
                      style={{ width: "100%", height: "100%", marginTop: 6 }}
                    />
                  </Box>
                  <Box sx={styles.imgBox}>
                    <img
                      alt="social link"
                      src={music}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                  <Box sx={styles.imgBox}>
                    <img
                      alt="social link"
                      src={spotify}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                  <Box sx={styles.imgBox}>
                    <img
                      alt="social link"
                      src={play}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                </ListItem>
              </List>
            </Box>
            <Box sx={styles.innerContanier}>
              <Link to="/termsandcondition" style={{ textDecoration: "none" }}>
                <Typography
                  sx={{
                    color: "white",
                    fontSize: 18,
                  }}
                >{`Terms And Conditions`}</Typography>
              </Link>
              <Link to="/privacypolicy" style={{ textDecoration: "none" }}>
                <Typography
                  sx={{
                    color: "white",
                    fontSize: 18,
                    marginLeft: 1,
                  }}
                >
                  {` |  Privacy Policy`}
                </Typography>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;

const styles = {
  footerSec: {
    marginTop: 5,
    marginBottom: 0,
    h2: {
      fontSize: "40px",
      textAlign: "right",
      color: "#FFF",
      textTransform: "uppercase",

      fontSize: "40px",
      fontWeight: "600",
    },
  },
  imgBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: { sm: 30, xs: 15 },
    height: { sm: 30, xs: 15 },
    borderRadius: "50%",
    backgroundColor: "#373739",
    padding: { sm: "0.8rem", xs: "0.5rem" },
    margin: "0.5rem",
    transition: "all ease-in-out 0.5s",
    ":hover": {
      backgroundColor: "#FCA10C",
    },
  },
  socialtags: {},
  icon: {
    width: "40px",
    height: "40px",
    cursor: "pointer",
    color: "white",
    backgroundColor: "rgb(255, 255, 255,0.2)",
    borderRadius: "50%",
    padding: "0.5rem",
    marginX: "1rem",
    ":hover": {
      backgroundColor: "#fda10b",
      transition: "all ease 0.5s",
    },
  },
  innerContanier: {
    alignSelf: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
