import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Album from "./components/album/Album";
import Music from "./components/Music/Music";
import Video from "./components/Video/Video";
import Store from "./components/Store/store";
import Contact from "./components/contact/Contact";
import Product from "./components/Product/Product";
import ProductDetails from "./components/Product/ProductDetails";
import { useEffect, useState } from "react";
import CustomSwitch from "../src/components/CustomSwitch";
import AddToCart from "./components/Cart/AddToCart";
import Login from "./components/Login/Login";
import TermAndCondition from "./components/TermAndConditions/TermAndConditions";
import PravicyPolicy from "./components/PrivacyPolicy/PravicyPolicy";
import Events from "./components/Events/Events";
import Blogs from "./components/Blogs/Blogs";
import ForgetPassword from "./components/forgetPassword/ForgetPassword";
import { useDispatch } from "react-redux";
import { setUser } from "../src/slices/userSlice";
import Details from "./components/BlogDetails/Details";
import EventDetails from "./components/Event-Details/EventDetails";
import Pricing from "./components/pricing/Pricing";
function App() {
  const dispatch = useDispatch();
  const [active, setActive] = useState("Home");
  useEffect(() => {
    const storedUserJSON = localStorage.getItem("user");
    if (storedUserJSON) {
      try {
        // Parse the JSON string into a JavaScript object
        const user = JSON.parse(storedUserJSON);
        dispatch(setUser(user));
      } catch (err) {
        console.log(err);
      }
    }
  }, []);
  return (
    <main>
      <CustomSwitch>
        <Routes>
          <Route
            path="/"
            element={<Home active={active} setActive={setActive} />}
          />
          <Route
            path="/music/:album"
            element={<Music active={active} setActive={setActive} />}
          />
          {/* <Route
            path="/music/:name"
            element={<Music active={active} setActive={setActive} />}
          /> */}
          <Route
            path="/music"
            element={<Music active={active} setActive={setActive} />}
          />
          <Route
            path="/album"
            element={<Album active={active} setActive={setActive} />}
          />
          <Route
            path="/video"
            element={<Video active={active} setActive={setActive} />}
          />
          <Route
            path="/store"
            element={<Store active={active} setActive={setActive} />}
          />
          <Route
            path="/contact"
            element={<Contact active={active} setActive={setActive} />}
          />
          <Route
            path="/product"
            element={<Product active={active} setActive={setActive} />}
          />
          <Route
            path="/product/:slug"
            element={<ProductDetails active={active} setActive={setActive} />}
          />
          <Route
            path="/add-to-cart"
            element={<AddToCart active={active} setActive={setActive} />}
          />
          <Route
            path="/login"
            element={<Login active={active} setActive={setActive} />}
          />
          <Route
            path="/termsandcondition"
            element={<TermAndCondition active={active} setActive={setActive} />}
          />
          <Route
            path="/privacypolicy"
            element={<PravicyPolicy active={active} setActive={setActive} />}
          />
          <Route
            path="/events"
            element={<Events active={active} setActive={setActive} />}
          />
          <Route
            path="/blogs"
            element={<Blogs active={active} setActive={setActive} />}
          />
          <Route
            path="/forgetPassword"
            element={<ForgetPassword active={active} setActive={setActive} />}
          />
          <Route
            path="/blog-details"
            element={<Details active={active} setActive={setActive} />}
          />
          <Route
            path="/event-details/:id"
            element={<EventDetails active={active} setActive={setActive} />}
          />
          <Route
            path="/pricing"
            element={<Pricing active={active} setActive={setActive} />}
          />
        </Routes>
      </CustomSwitch>
    </main>
  );
}

export default App;
